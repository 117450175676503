<template>
  <v-app class="page_bg">

    <!-- Code for BISC start -->
    <div class="main_div" v-if="is_bisc_display">
        <BISC :invoiceno ="invoiceno"/>
    </div>
    <!-- Code for BISC end -->

    <div class="main_div" v-if="isMainDiv">
      <v-card 
        class="mx-auto"
        max-width="450"
        style="margin-top:100px;border-radius:15px"
      >

      <!-- Show response message popup/dialog box -->
      <v-snackbar
        v-model="snackbar"
        :color="color"
        :multi-line="mode === 'multi-line'"
        :timeout="timeout"
        :top="true"
        :vertical="mode === 'vertical'"
        rounded="pill"
      >
        {{ popup_text }}
      <v-btn text @click="snackbar = false" style="color:#ffffff;">Close</v-btn>
      </v-snackbar>

      <!-- Logo of the merchant -->
      <div class="avatar_div">
        <v-card-actions class="justify-center">
          <v-avatar size="100px" style="margin-top:-56px;" class="justify-center avatar_img" >
          <v-img
              :src="merchant_logo"
              alt="merchant logo"
              title="merchant logo"
              style="background-color:#fff;width:100px"
              contain
          ></v-img>
          </v-avatar>
        </v-card-actions>
      </div>
      
      <!-- Customer name and sign out section -->
      <div class="name_logout" v-if="myaccount">
        <v-container style="margin-top:-20px">
          <v-row style="height:30px;">
            <v-col md="12" sm="12" xs="12">
              <p class="client_name_andLogout">{{customer_name}} <span style="color:#0b4262">|</span>
                 <span @click="logout_user"> <v-btn 
                  text
                  elevation="0"
                  class="logout_btn"
                 >Logout</v-btn>
                </span>
              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- Payment ,client name,Taka amount,order_id section-->
      
      <div class="pay_amount_main_div" v-if="normal_user">
        <v-container style="margin-top:-20px;">
          <v-row style="height:60px;margin-top:-10px;">
            <v-col md="6" sm="6" xs="12">
              <p class="payment_to">Payment to</p>
              <p class="client_name">{{merchant_name}}</p>
            </v-col>

            <v-col md="6" sm="6" xs="12" class="border_left">
              <p class="payment_amount">{{sp_amount_display}} &nbsp;&nbsp;&nbsp;<span style="font-size:13px">{{sp_currency}}</span></p>
              <p class="order_id"><span style="font-weight:bold">Order ID</span>: {{sp_orderid}}</p>
            </v-col>
          </v-row>
        </v-container>
      </div>


       <!-- When surcharge active -->
       <div class="pay_amount_main_div" v-if="tdr_visibility">

        <!-- Design - 1 -->
        <v-container style="margin-top:-20px;">
          <v-row style="margin-top:-10px;">

            <v-col md="6" sm="6" xs="12">
              <p class="payment_to">Payment to</p>
              <p class="client_name">{{merchant_name}}</p>
            </v-col>

            <v-col md="6" sm="6" xs="12" class="border_left2">

              <p class="tdr_amount_label">
                Amount: ৳<span class="amount_label">{{sp_amount_display}}</span>
              </p>

              <p class="additional_fees">
                 Additional Fees: ৳<span class="amount_label">{{additional_fees_amt}}</span>
                 <v-icon 
                  class="tdrInfoBtn"
                  @click="showAdditionalFeesPopUp()"
                  >
                   mdi-help-circle-outline
                  </v-icon>
              </p>

              
              <p class="payment_amount">Payable: {{payable_amt}} 
                &nbsp;&nbsp;&nbsp;<span style="font-size:13px">{{sp_currency}}</span></p>
              <p class="order_id"><span style="font-weight:bold">Order ID</span>: {{sp_orderid}}</p>

            </v-col>
          </v-row>
        </v-container>


        <!-- <v-container style="margin-top:-20px;">
          <v-row style="margin-top:-10px;">

            <v-col md="4" sm="4" xs="12">
              <p class="payment_to">Payment to</p>
              <p class="client_name">{{merchant_name}}</p>
            </v-col>

            <v-col md="8" sm="8" xs="12" class="border_left2">

              <v-row>
                  <v-col md="5" sm="5" xs="12">
                    <p class="tdr2">
                      Amount: ৳ <span class="tdr3">{{sp_amount_display}}</span>
                    </p>
                  </v-col>

                  <v-col md="7" sm="7" xs="12" style="margin-left: -5px;">
                     <p class="tdr2">
                      Additional Fees: ৳ <span class="tdr3">{{additional_fees_amt}}</span>
                       <v-icon 
                        style="font-size: 13px;margin-left: 4px;color:rgb(224, 205, 101)"
                        @click="showAdditionalFeesPopUp()"
                        >
                        mdi-help-circle-outline
                        </v-icon>
                    </p>
                  </v-col>

                  
              </v-row>

              <p class="tdr4">Payable: {{payable_amt}} 
                &nbsp;&nbsp;&nbsp;<span style="font-size:13px">{{sp_currency}}</span></p>
              <p class="order_id"><span style="font-weight:bold">Order ID</span>: {{sp_orderid}}</p>

             

            </v-col>
          </v-row>
        </v-container> -->


      </div>




      
      <!-- Tabs or Nav section start-->
      <div style="background-color:#fff;margin:5px">
        <v-card elevation="0">
          
          <v-tabs
            v-model="active_tab"
            background-color="#fff"
            dark
            centered
            icons-and-text
            height="55px"
          >
            <v-tabs-slider></v-tabs-slider>
            
            <v-tab href="#cards" class="tabs_text_style" id="card_style" @click="show_cards(); tabIndex(0)"
             v-if="isCard" style="dwidth">CARDS
              <v-icon size="22px" style="color:#fff">mdi-credit-card-multiple</v-icon>
            </v-tab>
            
             <v-tab href="#mfs" class="tabs_text_style" id="mbanking_style" @click="show_mbanking(); tabIndex(1)"
             v-if="isMbanking" style="dwidth">mWALLET
              <v-icon  size="22px" style="color:#fff">mdi-cellphone</v-icon>
            </v-tab>
            
            <v-tab href="#ibanking" class="tabs_text_style" id="ibanking_style" @click="show_ibanking(); tabIndex(2)"
             v-if="isIBanking" :style="dwidth">iBANKING 
              <v-icon  size="22px" style="color:#fff">mdi-bank</v-icon>
            </v-tab>
          
            <v-tab href="#account" class="lasttabs_text_style" id="account_style"  @click="show_account(); tabIndex(3)" 
            v-if="no_login" :style="dwidth">LOGIN
              <v-icon  size="22px" style="color:#fff">mdi-account</v-icon>
            </v-tab>

            <v-tab href="#account" class="lasttabs_text_style" id="account_style"  @click="show_account(); tabIndex(3)"
             v-if="myaccount" :style="dwidth">PROFILE
              <v-icon  size="22px" style="color:#fff">mdi-account-check</v-icon> 
            </v-tab>
          </v-tabs>


          <v-tabs-items v-model="active_bar">
          
          <!-- Card tab content -->
          <v-tabs-items v-if="card_tab">
             <v-container  grid-list-xl justify-space-around wrap fluid class="containerStyle">
                
                <!-- <v-layout wrap class="fastpay_div" v-if="myaccount && fastPayStatus">
                    <v-flex xs12 md12 sm12 style="margin-top:-10px">
                       <p class="fastpaytext">Fast Pay</p>
                       <FastPay @card_event="show_card_form" :sp_amount="sp_amount" :is_emi_visible="is_emi_visible" :store_id="store_id"></FastPay>
                    </v-flex>
                </v-layout> -->
                
                 <v-layout wrap class="rowStyle" v-if="is_emi_visible">
                  <v-flex xs12 md12 sm12>
                    <v-row style="height:60px">
                    <v-col md="12" sm="12" xs="12" >
                      <v-select
                        v-model="editedItem.emi"
                        :items="emi_options"
                        item-text="option_val"
                        item-value="select_val"
                        label="Avail EMI"
                        outlined
                        dense
                        style="height:40px;"
                      >
                      <template slot='selection'>
                          <span class="emiDisplaytext">{{editedItem.emi}}</span>
                      </template>

                      </v-select>
                    </v-col>
                    </v-row>
                   </v-flex>
                 </v-layout>
                 

                 <v-layout wrap class="rowStyle">
                  <v-flex xs4 md4 sm4 v-for="(cards,index) in cards_gateways" :key="index">
                    <v-hover style="max-height:67px !important" v-slot:default="{ hover }">
                      <v-img
                        :src ="image_base_url+ cards.hover_image"
                        :alt = "cards.slug"
                        max-width = "100%"
                        @click="goCardGateway(cards.slug,cards.gateway_id,cards.gateway_type_id)"
                        :class=" hover ? 'hover_img': 'normal_img'"
                      >
                       <v-img
                        :src ="image_base_url+ cards.image"
                        v-if="hover"
                        max-width = "100%"
                      >
                      </v-img>

                       <!-- Selected gateway icon -->
                        <v-img
                          :src ="image_base_url+ cards.image"
                          v-if="cards.gateway_id==cards_selected_gateway_iddd"
                          max-width = "100%"
                        >
                       </v-img>

                      </v-img>
                     
                    </v-hover>
                  </v-flex>


                  <!-- <v-flex xs4 md4 sm4 style="cursor:pointer">
                      <v-img
                        :src ="pci_dss_img"
                        alt="pci dss logo"
                        max-width = "100%"
                        @click="PCI_DSS()"
                      >
                      </v-img>
                  </v-flex> -->

                </v-layout>
              </v-container>
          </v-tabs-items>
            
          <!-- Save card section form start -->
           <v-tabs-items v-if="saved_card_tab1" >
              <v-container>
                <div style="height:41px;" v-if="hidecardmenu">
                  <ul style="list-style-type: none;">
                    <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/master.png')"
                        alt="master card"
                        width="30"
                        v-if="masterCardDeactive"
                      >
                      </v-img>

                      <v-img
                        :src="require('./assets/logos/ad_logo/master_h.png')"
                        alt="master card"
                        width="30"
                        v-if="masterCardActive"
                      >
                      </v-img>
                    </li>

                    <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/visa.png')"
                        alt="visa card"
                        width="30"
                        style="margin-left:5px"
                        v-if="visaDeactive"
                      >
                      </v-img>

                    <v-img
                        :src="require('./assets/logos/ad_logo/visa_h.png')"
                        alt="visa card "
                        width="30"
                        style="margin-left:5px"
                        v-if="visaActive"
                      >
                      </v-img>
                    </li>

                    <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/amex.png')"
                          alt="american exprees"
                          width="30"
                          style="margin-left:5px"
                          v-if="amexDeactive"
                      >
                      </v-img>

                      <v-img
                      :src="require('./assets/logos/ad_logo/amex_h.png')"
                        alt="american exprees"
                        width="30"
                        style="margin-left:5px"
                        v-if="amexActive"
                      >
                      </v-img>
                    </li>

                    <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/unionpay.png')"
                          alt="unionpay Deactive"
                          width="30"
                          style="margin-left:5px"
                          v-if="unionpayDeactive"
                        >
                        </v-img>

                      <v-img
                        :src="require('./assets/logos/ad_logo/unionpay_h.png')"
                          alt="unionpay Active"
                          width="30"
                          style="margin-left:5px"
                          v-if="unionpayActive"
                        >
                        </v-img>
                    </li>

                    <li style="float:left;">
                      <v-img
                        :src="require('./assets/logos/ad_logo/diners.png')"
                          alt="diners-club"
                          width="30"
                          style="margin-left:5px"
                          v-if="dinersDeactive"
                        >
                      </v-img>

                      <v-img
                        :src="require('./assets/logos/ad_logo/diners_h.png')"
                        alt="diners-club"
                        width="30"
                        style="margin-left:5px"
                        v-if="dinersActive"
                        >
                      </v-img>
                    </li>

                    <li style="float:left;margin-left:8px;margin-top:0px;">
                      <v-btn text x-small color="primary" class="other_btn" @click="othersCard(); tabIndex(4)">
                        Other Cards
                      </v-btn>
                    </li>

                    <li style="float:left;margin-left:10px;margin-top:0px;" v-if="myaccount">
                      <v-btn text x-small color="primary" class="saved_card_btn" @click="SavedcardsAction">
                        Saved Cards
                      </v-btn>
                    </li>
                  </ul>
                </div> 

                 <v-row v-if="other_cards_display" style="min-height:230px;">
                   <v-col md="4" sm="4" xs="4">
                    <v-hover>
                      <v-img
                        slot-scope = "{ hover }"
                        v-if = "hover"
                        max-width = "140px"
                        :src="require('./assets/logos/nexus_debit_hover.png')"
                        alt = "Nexus Debit"
                        style="cursor:pointer;border-bottom:4px solid #0F9750;border-radius:4px"
                      >
                      </v-img>
                      <v-img
                        v-else
                        :src="require('./assets/logos/nexus_debit.png')"
                        max-width = "140px"
                      >
                      </v-img>
                    </v-hover>
                   </v-col>

                    <v-col md="4" sm="4" xs="4">
                    <v-hover>
                      <v-img
                        slot-scope = "{ hover }"
                        v-if = "hover"
                        max-width = "140px"
                        :src="require('./assets/logos/qcash_h.png')"
                        alt = "Qcash"
                        style="cursor:pointer;border-bottom:4px solid #0F9750;border-radius:4px"
                      >
                      </v-img>
                      <v-img
                        v-else
                        :src="require('./assets/logos/qcash.png')"
                        max-width = "140px"
                      >
                      </v-img>
                    </v-hover>
                   </v-col>
                 </v-row>
                  
                  <v-row style="min-height:230px;" v-if="saved_card_section">
                   <v-col md="12" sm="12" xs="12">
                       <FastPay ref="form"/>
                   </v-col>
                 </v-row>

                <v-form ref="form_cards" style="margin-top:-10px" v-model="isFormValid" v-if="hideCard_div">
                  <v-row style="height:60px">
                    <v-col :md="colWidth" :sm="colWidth" xs="12">
                      <v-text-field
                        v-model="editedItem.card_number"
                        :rules="CardNumberRules" 
                        label="Card number"
                        oninput = "this.value = this.value.replace(/[^0-9 ]/g, '').replace(/(\..*)\./g, '$1');"
                        required
                        outlined
                        dense
                        maxlength="19"
                        class="cc-number-input"
                        v-mask="'#### #### #### ####'"
                        autofocus
                      >
                      </v-text-field> 
                    </v-col>
                   
                    <v-col md="6" sm="6" xs="12" v-if="is_emi_visible">
                      <v-select
                        v-model="editedItem.emi"
                        :items="emi_options"
                        item-text="option_val"
                        item-value="select_val"
                        label="Avail EMI"
                        outlined
                        dense
                        style="height:40px;"
                      >
                      <template slot='selection'>
                          <span class="emiDisplaytext">{{editedItem.emi}}</span>
                      </template>

                      </v-select>
                    </v-col>

                  </v-row>
                  
                  <v-row style="height:60px">
                    <v-col md="4" sm="4" xs="12">
                      <v-text-field
                        v-model="editedItem.mm"
                        label="MM"
                        :rules="[v => v.length == 2||'Month is required']" 
                        required
                        outlined
                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                        maxlength="2"
                        dense
                        autocomplete="off"
                        id="mm"
                      >
                      </v-text-field> 
                    </v-col>

                    <v-col md="4" sm="4" xs="12">
                        <v-text-field
                          v-model="editedItem.yyyy"
                          label="YYYY"
                          :rules="[v => v.length == 4||'Year is required']" 
                          required
                          outlined
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          maxlength="4"
                          dense
                          autocomplete="off"
                          id="yyyy"
                        >
                        </v-text-field> 
                    </v-col>

                    <v-col md="4" sm="4" xs="12" >
                        <v-text-field
                          v-model.number="editedItem.cvc_cvv"
                          label="CVC/CVV"
                          :rules="cvc_cvvRules" 
                          autocomplete="off"
                          maxlength="4"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          required
                          outlined
                          dense 
                          id="cvc_cvv"
                        >
                        </v-text-field> 
                    </v-col>
                  </v-row>

                  <v-row style="height:60px">
                    <v-col md="12" sm="12" xs="12" >
                        <v-text-field
                          v-model="editedItem.card_holder_name"
                          label="Card Holder Name"
                          :rules="[v => !!v || 'Card holder name is required']"
                          required
                          outlined
                          dense
                          autocomplete="off"
                          id="card_holder_name"
                        >
                        </v-text-field> 
                    </v-col>
                  </v-row>

                  <div style="background-color:#fff;margin-top:7px">
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="checkbox"
                          :disabled="!isFormValid"
                          label="Save card &amp; remember me"
                          style="margin-top:-5px;height:30px;padding-left:5px"
                          @click="CheckLogin()"
                          >
                        </v-checkbox>
                      </v-col>
                    </v-row>

                    <v-row style="height:20px" >
                      <v-col>
                        <p class="tos">By checking this box you agree to the 
                          <v-btn text small @click = "display_toc" style="text-decoration: underline;text-transform: capitalize">
                            Term & Conditions</v-btn>
                        </p>
                      </v-col>
                    </v-row>
                  </div>

                </v-form>

                   
                  <div class="paynow_div" v-if="hideshowpaynow">
                     <v-row>
                       <v-col md="6" sm="6" xm="6">
                          <v-btn block color="red" class="paynow_btn" @click="cancel_btn" dark >Cancel</v-btn>
                       </v-col>

                       <v-col md="6" sm="6" xm="6">
                          <v-btn block depressed style="color:#fff" color="#0F9750" class="paynow_btn"
                            @click="save_card_info"
                            :disabled="!isFormValid"
                          > Pay Now </v-btn>
                       </v-col>

                     </v-row>
                     
                    
                  </div>
                  
              </v-container>
           </v-tabs-items>
          <!-- Save card section form end -->


          <v-tabs-items v-if="show_terms_conditions">
              <v-row justify="center" >
              <v-dialog v-model="toc_dialog" scrollable max-width="400px" persistent>
                <v-card>
                  <v-card-title>Term & Conditions</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 320px;">
                    <p style="text-align:justify"><br>
                      We respect your privacy and want to protect your personal information. Data that we collect<br><br>

                      <b>1)</b> I understand that if at any time any information furnished against shurjoPay or
                      any kind of fraudulent transaction is found incorrect then the contact shall be
                      deemed to be cancelled and shall be liable for action as per shurjoMukhi decision<br><br>
                      
                      <b>2)</b> I understand that if at any time any information furnished against shurjoPay or
                      any kind of fraudulent transaction is found incorrect then the contact shall be
                      deemed to be cancelled and shall be liable for action as per shurjoMukhi decision<br><br>

                      <b>3)</b> I hereby certify that I have carefully read the terms and conditions of shurjoPay 
                      and I undertake to comply with the terms and conditions therein.<br><br>

                    </p>

                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
  
                    <v-list-item>
                      <v-list-item-content style="padding-right:6px">
                        <v-checkbox
                          v-model="toc_checkbox"
                          label="I agree with Term & Conditions"
                          style="margin-left:10px"
                        >
                        </v-checkbox>
                      </v-list-item-content>

                      <v-list-item-content>
                          <v-btn text color="primary" dark type="submit" @click="toc_dialog = false" style="float:right;text-transform: capitalize">Close</v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-actions>

                </v-card>
              </v-dialog>
                </v-row>
          </v-tabs-items>





            <!-- Mobile Banking -->
            <v-tabs-items v-if="mobile_banking">
              <v-container grid-list-xl  wrap fluid class="mobileBankingSec" >
                <v-layout wrap class="rowStyle" >
                  <v-flex xs4 md4 sm4 v-for="(mfs,index) in mfs_gateways" :key="index">

                    <v-hover style="max-height:67px !important" v-slot:default="{ hover}">
                      <v-img
                        :src ="image_base_url+ mfs.hover_image"
                        :alt = "mfs.slug"
                        max-width = "100%"
                        @click="go_mfs_gateway(mfs.gateway_id,mfs.gateway_type_id)"
                        :class=" hover ? 'hover_img': 'normal_img'"
                      >
                        <v-img
                          :src ="image_base_url+ mfs.image"
                          v-if="hover"
                          max-width = "100%"
                        >
                       </v-img>

                        <!-- Selected gateway icon -->
                        <v-img
                          :src ="image_base_url+ mfs.image"
                          v-if="mfs.gateway_id==mfs_selected_gateway_iddd"
                          max-width = "100%"
                        >
                       </v-img>

                      </v-img>

                    </v-hover>
                   

                  </v-flex>
                </v-layout>
              </v-container>

            </v-tabs-items>

            <!-- Internet banking -->
            <v-tabs-items v-if="i_banking">
              <v-container grid-list-xl justify-space-around wrap fluid class="internetBankingSec">
                 <v-layout wrap class="rowStyle">
                  <v-flex xs4 md4 sm4 v-for="(ibanking,index) in ibanking_gateways" :key="index">
                    <v-hover style="max-height:67px !important" v-slot:default="{ hover }">
                      <v-img
                        :src ="image_base_url+ ibanking.hover_image"
                        :alt = "ibanking.slug"
                        max-width = "100%"
                        @click="go_ibanking_gateway(ibanking.slug,ibanking.gateway_id,ibanking.gateway_type_id)" 
                        :class=" hover ? 'hover_img': 'normal_img'"
                      >
                       <v-img
                        :src ="image_base_url+ ibanking.image"
                        v-if="hover"
                        max-width = "100%"
                       >
                      </v-img>

                       <!-- Selected gateway icon -->
                        <v-img
                          :src ="image_base_url+ ibanking.image"
                          v-if="ibanking.gateway_id==ibanking_selected_gateway_iddd"
                          max-width = "100%"
                        ></v-img>

                      </v-img>
                     
                    </v-hover>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tabs-items>

             <!-- Loading Actions -->
             <!-- hide-overlay  -->
            <v-tabs-items v-if="loading_tab">
               <v-dialog v-model="loading_dialog" persistent width="385">
                <v-card color="#257D3E" dark >
                  <v-card-text class="text-center">
                    <p style="font-size:17px;font-weight:bold;color:#fff;margin-bottom:4px;padding-top:10px">
                      Please wait while processing ...
                    </p>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-tabs-items>

            <!-- User account --Login,Signup,Forgot Password -->
            <v-tabs-items v-if="user_account">
              <v-container>

                <div v-if="login_div" style="min-height:250px;margin-top:20px">
                  <v-form ref="form5">
                  <v-row justify="center">
                    <v-col md="10" sm="10" xs="10" style="height:60px">
                      <v-text-field
                          v-model="loginItem.login_mobile_number"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          :rules="mobileNumberRule"
                          label="Mobile number"
                          prepend-inner-icon="mdi-cellphone-basic"
                          required
                          outlined
                          dense
                          maxlength="11"
                          autocomplete="off"
                          placeholder="e.g 01XXXXXXXXX"
                          autofocus
                        >
                        </v-text-field> 
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col md="10" sm="10" xs="10" style="height:60px">
                      <v-text-field
                        name="password"
                        v-model="loginItem.login_password"
                        :value="myPass"
                        label="Password"
                        :rules="passwordRules"
                        prepend-inner-icon="mdi-lock"
                        :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="() => (value = !value)"
                        :type="value ? 'password' : 'text'"
                        outlined
                        dense
                        id="passwordField"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row justify="center" align="end" style="margin-bottom:-15px;margin-top:-10px;">
                      <v-col md="4" sm="4" xs="4">
                        <!-- -->
                      </v-col>

                      <v-col md="8" sm="8" xs="8">
                        <v-btn text small color="primary" class="forgot_btn" @click="forgot_password">Forgot Password ?</v-btn>
                      </v-col>
                  </v-row>

                  </v-form>
                  <v-list>
                    <v-list-item style="margin-top:0px">

                      <v-list-item-content style="padding-right:6px">
                        <v-btn 
                        text small
                        color="primary"
                        dark
                        @click="show_singup_form()" 
                        style="text-transform:capitalize;margin-left:-40px;"
                        >Create Account</v-btn>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-btn color="primary" elevation="1" dark  @click="login_status" style="float:right;text-transform:capitalize">Login</v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                <!-- Recover Password Modal...Take Mobile Number -->
                  <v-row justify="center">
                    <v-dialog v-model="forgot_password_modal" max-width="400px" persistent>
                      <v-card>
                        <v-card-title class="justify-center">
                            <v-icon size="58px" color="primary">mdi-lock-reset</v-icon>
                        </v-card-title>
                        <v-card-title class="justify-center" style="margin-top:-30px">Recover Password</v-card-title>
                        <v-card-text>
                          <v-list subheader two-line>
                            <v-list-item>
                                <v-list-item-content>
                                  <v-form ref="form2" @submit.prevent="send_otp">
                                  <v-text-field
                                      v-model="forgot_password_mobile"
                                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                      :rules="mobileNumberRule"
                                      label="Mobile Number"
                                      prepend-inner-icon="mdi-cellphone-basic"
                                      placeholder="e.g 01XXXXXXXXX"
                                      required
                                      outlined
                                      maxlength="11"
                                      autocomplete="off"
                                      autofocus
                                    >
                                    </v-text-field>
                                  </v-form>
                                </v-list-item-content>
                            </v-list-item>
      
                            <v-list-item style="margin-top:-33px">
                              <v-list-item-content style="padding-right:6px">
                                <v-btn large color="red" dark @click="send_otp_back()" style="text-transform: capitalize">Close</v-btn>
                              </v-list-item-content>

                              <v-list-item-content>
                                  <v-btn large color="primary" dark type="submit" @click="send_otp" style="float:right;text-transform: capitalize">Next</v-btn>
                              </v-list-item-content>
                            </v-list-item>
                                
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>

                <!-- Forgot Password Modal ... Take OTP Input -->
                  <v-row justify="center">
                    <v-dialog v-model="forgot_password_otp_modal" max-width="400px" persistent>
                      <v-card>
                        <v-card-subtitle class="justify-center" style="padding-top:30px;font-size:15px;margin-left:15px">
                          A text message with a verification code was just sent to <b> {{this.otp_send_text_msg}} </b>
                        </v-card-subtitle>
                        <v-card-text>
                            <v-list subheader two-line>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-form ref="form_otp" @submit.prevent="submit_otp">
                                    <v-text-field
                                      v-model="forgot_password_otp"
                                      :rules="[v => !!v || 'OTP is required']"
                                      label="Enter OTP"
                                      autocomplete="off"
                                      required
                                      outlined
                                      autofocus
                                    >
                                    </v-text-field>
                                  </v-form>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item style="margin-top:-33px">
                                <v-list-item-content style="padding-right:6px">
                                  <v-btn large color="red" dark @click="submit_otp_back" style="text-transform: capitalize">Close</v-btn>
                                </v-list-item-content>

                                <v-list-item-content>
                                    <v-btn large color="primary" dark type="submit" @click="submit_otp" style="float:right;text-transform: capitalize">Next</v-btn>
                                </v-list-item-content>
                              </v-list-item>

                            </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>

                <!-- Reset New Password Modal ...Take new password as a input-->
                  <v-row justify="center">
                    <v-dialog v-model="new_password_modal" max-width="400px" persistent>
                      <v-card>
                        <v-card-title class="justify-center">Set New Password</v-card-title>
                        <v-card-text>
                            <v-list subheader two-line>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-form ref="form_password_reset" @submit.prevent="resetPassword">
                                    <v-text-field
                                      v-model="new_password"
                                      name="password"
                                      :value="new_password_val"
                                      :rules="[v => !!v || 'New password is required']"
                                      label="New Password"
                                      autocomplete="off"
                                      :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                                      @click:append="() => (value = !value)"
                                      :type="value ? 'password' : 'text'"
                                      required
                                      outlined
                                      autofocus>
                                    </v-text-field>
                                    
                                    <v-text-field
                                      v-model="confirmed_new_password"
                                      :value="confirmed_new_password_val"
                                      :rules="[(new_password === confirmed_new_password) || 'Password must match']"
                                      label="Confirmed New Password"
                                      autocomplete="off"
                                      :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                                      @click:append="() => (value = !value)"
                                      :type="value ? 'password' : 'text'"
                                      required
                                      outlined>
                                    </v-text-field>
                                  </v-form>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item style="margin-top:-33px">
                                <v-list-item-content style="padding-right:6px">
                                  <v-btn large color="red" dark @click="resetPassword_back" style="text-transform: capitalize">Close</v-btn>
                                </v-list-item-content>

                                <v-list-item-content>
                                  <v-btn large color="primary" dark type="submit" @click="resetPassword" style="float:right;text-transform: capitalize">Submit</v-btn>
                                </v-list-item-content>
                              </v-list-item>

                            </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>
                
                <!-- Registration OTP Modal...Take OTP input for account activation -->
                  <v-row justify="center">
                    <v-dialog v-model="resigtration_otp" max-width="400px" persistent>
                      <v-card>
                        <v-card-subtitle class="justify-center" style="padding-top:30px;font-size:15px;margin-left:15px">
                            A text message with a verification code was just sent to <b> {{this.resigter_otp_text}} </b>
                        </v-card-subtitle>

                        <v-card-text>
                          <v-list subheader two-line>
                            <v-list-item>
                              <v-list-item-content>
                                <v-form ref="register_otpp" @submit.prevent ="register_otp_confirm">
                                  <v-text-field
                                    v-model="resigter_otp"
                                    :rules="[v => !!v || 'OTP is required']"
                                    label="Enter OTP"
                                    required
                                    outlined
                                    dense
                                    autocomplete="off"
                                    autofocus
                                  >
                                  </v-text-field>
                                </v-form>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item style="margin-top:-33px">

                                <v-list-item-content style="padding-right:6px">
                                  <v-btn v-if="dispaly_time_otp" large color="#009688" dark>
                                    <v-icon left>mdi-alarm</v-icon>{{display_min}}:{{display_sec}}
                                  </v-btn>
                                  <v-btn v-if="display_resend_otp_text" large color="#009688" dark @click="resend_otp" 
                                    style="text-transform: capitalize">{{resent_otp_text}}
                                  </v-btn>
                                </v-list-item-content>
                                
                                <v-list-item-content style="padding-right:6px">
                                  <v-btn large color="red"  dark @click="close_reg_otp_modal" style="float:right;text-transform: capitalize">Close</v-btn>
                                </v-list-item-content>

                                <v-list-item-content>
                                  <v-btn large color="primary" type="submit" dark @click="register_otp_confirm" style="float:right;text-transform: capitalize">Submit</v-btn>
                                </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>

                  <!--Active account ...If the user miss account varification during registration.When login check account activation  -->
                  <v-row justify="center">
                    <v-dialog v-model="active_account_modal" max-width="400px" persistent>
                      <v-card>
                        <v-card-title class="justify-center" style="font-size: 15px;color: red;font-weight: bold;">
                          Your account is not active, Please Active.
                        </v-card-title>

                        <v-card-text>
                          <v-list subheader two-line>
                            <v-list-item>
                              <v-list-item-content>
                                <v-form ref="form22" @submit.prevent="send_active_otp">
                                <v-text-field
                                  v-model="active_account_mobile"
                                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                  :rules="mobileNumberRule"
                                  label="Mobile Number"
                                  prepend-inner-icon="mdi-cellphone-basic"
                                  required
                                  outlined
                                  maxlength="11"
                                  autocomplete="off"
                                  autofocus
                                  >
                                </v-text-field>
                                </v-form>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item style="margin-top:-33px">
                              <v-list-item-content style="padding-right:6px">
                                <v-btn large color="red" dark @click="active_account_modal=false" style="text-transform: capitalize">Close</v-btn>
                              </v-list-item-content>

                              <v-list-item-content>
                                <v-btn large color="primary" type="submit" dark @click="send_active_otp" style="float:right;text-transform: capitalize">Active Account</v-btn>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>

                  <!-- Confirm OTP Modal for account activation-->
                  <v-row justify="center">
                    <v-dialog v-model="active_acc_otp" max-width="400px" persistent>
                      <v-card>
                        <v-card-subtitle class="justify-center" style="padding-top:30px;font-size:15px;margin-left:15px">
                          A text message with a verification code was just sent to <b> {{this.active_account_otp_input_text}} </b>
                        </v-card-subtitle>

                        <v-card-text>
                          <v-list subheader two-line>
                            <v-list-item>
                              <v-list-item-content>
                                <v-form ref="active_otpp" @submit.prevent="active_otp_confirm">
                                  <v-text-field
                                    v-model="active_account_otp_input"
                                    :rules="[v => !!v || 'OTP is required']"
                                    label="Enter OTP"
                                    required
                                    outlined
                                    autocomplete="off"
                                    autofocus
                                    >
                                    </v-text-field>
                                </v-form>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item style="margin-top:-33px">
                                <v-list-item-content style="padding-right:6px">
                                  <v-btn large color="red" dark @click="active_acc_otp=false" style="text-transform: capitalize">Close</v-btn>
                                </v-list-item-content>

                                <v-list-item-content>
                                  <v-btn large color="#0F9750" type="submit" dark @click="active_otp_confirm" style="float:right;text-transform: capitalize">Submit</v-btn>
                                </v-list-item-content>
                              </v-list-item>

                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-row>

                </div>

                <div v-if="signup_div" style="margin-top:5px;min-height:282px">
                  <v-form ref="form_register">
                    <v-row justify="center">
                      <v-col md="9" sm="9" xs="9" style="height:60px;padding:12px">
                        <v-text-field
                            v-model="signup_items.fullname"
                            :rules="fullnameRules"
                            label="Full name"
                            prepend-inner-icon="mdi-account"
                            autocomplete="off"
                            required
                            outlined
                            dense
                            maxlength="255"
                            autofocus
                          >
                          </v-text-field> 
                      </v-col>
                    </v-row>

                    <v-row justify="center" style="margin-top:-8px">
                      <v-col md="9" sm="9" xs="9" style="height:64px">
                        <v-text-field
                            v-model="signup_items.mobile_number"
                            :rules="mobile_num_sign_up"
                            label="Mobile number"
                            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                            prepend-inner-icon="mdi-cellphone-basic"
                            autocomplete="off"
                            required
                            outlined
                            dense
                            maxlength="11"
                          >
                          </v-text-field> 
                      </v-col>
                    </v-row>

                    <v-row justify="center" style="margin-top:-8px">

                      <v-col md="9" sm="9" xs="9" style="height:60px">
                        <v-text-field
                            v-model="signup_items.email_address"
                            label="Email address"
                            prepend-inner-icon="mdi-email"
                            autocomplete="off"
                            maxlength="150"
                            outlined
                            dense
                            id="email_style"
                          >
                          </v-text-field> 
                      </v-col>
                    </v-row>

                    <v-row justify="center" style="margin-top:-8px">

                      <v-col md="9" sm="9" xs="9" style="height:60px">
                        <v-text-field
                            v-model="signup_items.password"
                            name="password"
                            :value="myPass"
                            label="Password"
                            :rules="passwordRules"
                            prepend-inner-icon="mdi-key"
                            :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="() => (value = !value)"
                            :type="value ? 'password' : 'text'"
                            outlined
                            dense
                          >
                          </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>

                <v-row justify="center" style="margin-top:-4px">
                  <v-col md="6" sm="6" xs="6" style="height:38px">
                      <v-btn block color="primary" dark style="margin-top:0px" @click="register">Sign Up</v-btn>
                  </v-col>
                </v-row>
                </div>

                <div v-if="myaccount">
                    <MyAccount></MyAccount>
                </div> 
                
              </v-container>
            </v-tabs-items>

          
          
          <v-tabs-items>

            <!-- <v-row justify="center" align="center">
              <v-dialog v-model="popup_dialog" persistent max-width="390px">
                <v-tabs v-model="currentItem" centered height="55px">
                  
                  <v-tab href="#save_bks_num" @click="show_saved_bks()" >Saved Number</v-tab>
                  <v-tab href="#cancel_agreement">Agreement ID Cancel</v-tab>
                  
                </v-tabs>

                 <v-tabs-items v-model="currentItem">
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <h2>Hello</h2>
                        Content
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>


              </v-dialog>
            </v-row> -->

            <v-row justify="center" align="center">
              <v-dialog v-model="popup_dialog" persistent max-width="390px">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-form ref="form3" @submit.prevent="send_bkh">
                      <v-row>
                        <v-col cols="12" sm="12" md="12" xs="12">
                        <v-text-field
                          v-model="others.mobile_number"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          label="Your bKash Account number"
                          prepend-inner-icon="mdi-cellphone-basic"
                          placeholder="e.g 01XXXXXXXXX"
                          :rules="mobileNumberRule"
                          required
                          outlined
                          maxlength="11"
                          autocomplete="off"
                          style="margin-bottom:-30px;margin-top:36px"
                          autofocus
                          id="bks"
                        >
                        </v-text-field>
                        </v-col>
                      </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-subtitle class="text-center" style="font-size:12px; margin-top:-25px" v-if="myaccount && hasNumSaved">Your saved bKash account numbers</v-card-subtitle>
                 
                  <div style="margin-top:-22px;min-height:130px" v-if="myaccount && hasNumSaved">
                    <ul style="list-style-type:none;margin-left:14px;" v-for="(b,index) in saved_bkash" :key="index">
                      <li style="float:left;"><v-img :src="bkash_img" width="50px" max-height="27px"></v-img></li>
                      <li style="float:left;margin-left:10px">{{b.mobileno}}</li>
                      <li style="float:left;margin-left:20px">
                        <v-btn text small  @click ="transfer_number(b.mobileno)" style="text-transform: capitalize;font-size:14px">Select</v-btn>
                       </li>
                      <li style="float:left;margin-left:10px">
                        <v-btn text small @click="delete_bks_num(b.mobileno,index)">
                          <v-icon size="28px" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </li>
                    </ul>

                  </div>

                  <v-list>
                    <v-list-item style="margin-left:20px;margin-right:20px">
                      <v-list-item-content style="padding-right:6px">
                        <v-btn large color="error" dark @click="closePopup" style="text-transform: capitalize">Close</v-btn>
                      </v-list-item-content>

                      <v-list-item-content>
                          <v-btn large color="primary" dark type="submit" @click="send_bkh" style="float:right;text-transform: capitalize">Next</v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                </v-card>
              </v-dialog>
            </v-row>
          </v-tabs-items>




           <v-tabs-items>
            <v-row justify="center" align="center">
              <v-dialog v-model="tdrAdditionalFeePopUp" persistent max-width="390px">
                <v-card>
                  <v-card-text>
                    <v-container>

                      <p style="text-align: center;font-size: 16px;color: #000;font-weight:bold">
                        Charges and Additions</p>
                      <p style="text-align: center;font-size: 14px;color: #000;margin-top:-16px">
                        These are the charges against the total amount</p>

                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" >Title</th>
                              <th class="text-left" >Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td >Covenience Charge</td>
                              <td >{{additional_fees_amt}}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                      <v-btn 
                        color="primary"
                        style="margin-top:13px;text-transform:none"
                        elevation="0"
                        @click="tdrAdditionalFeePopUp = false">
                        Its Okay
                      </v-btn>

                    </v-container>
                  </v-card-text>
                  
                 
                  

                 

                </v-card>
              </v-dialog>
            </v-row>
          </v-tabs-items>


          <!-- Bkash Agreement Dialog start-->
           <v-tabs-items>
            <v-row justify="center" align="center">
              <v-dialog v-model="AgreementPopupDialog" persistent max-width="350px">
                <v-card>
                  <v-card-subtitle class="text-center" style="font-size:17px;padding-top:25px;color:#000;font-family: Times New Roman;">
                    Are you sure want to cancel Agreement ?
                  </v-card-subtitle>
                 
                  <v-list>
                    <v-list-item style="margin-left:20px;margin-right:20px">
                      
                       <v-list-item-content style="padding-right:6px">
                          <v-btn large color="primary" dark type="submit" @click="agreementYes" style="float:right;text-transform: capitalize">Yes</v-btn>
                      </v-list-item-content>

                      <v-list-item-content >
                        <v-btn large color="error" dark @click="CloseAgreementPopupDialog" style="text-transform: capitalize">No</v-btn>
                      </v-list-item-content>

                     
                    </v-list-item>
                  </v-list>

                </v-card>
              </v-dialog>
            </v-row>
          </v-tabs-items>
           <!-- Bkash Agreement Dialog end-->


          </v-tabs-items>
        </v-card>
      </div>
      <!-- Tabs or Nav section end -->

      <!-- Cancel Button Start -->
      <!-- <div style="margin-top:5px;padding-left:5px;padding-right:5px">
        <v-row justify="center" style="margin-top:-4px">
          <v-col md="6" sm="6" xs="6" style="height:38px;margin-bottom:38px;">
              <v-btn 
                block 
                color="red"
                dark
                style="margin-top:0px;text-transform:none;"
                @click="CancelPayment"
                >Cancel Payment</v-btn>
          </v-col>
        </v-row>
      </div> -->

       <!-- Cancel Button Start -->
      <div style="margin-top:5px;padding-left:5px;padding-right:5px">
        <v-row justify="center">
          <v-col md="8" sm="12" xs="12">
              <p style="font-size:12px;margin-bottom:7px;text-align:center">
                Do you want to cancel this transaction? 
                <span @click="CancelPayment" style="margin-left:2px;text-decoration: underline;color:#0f9750;cursor:pointer;">Click Here
                </span></p>
          </v-col>
        </v-row>
      </div>
       <!-- Cancel Button End -->


       <!-- paynow tdr -->
       <div class="paynow_div_tdr" v-if="tdr_visibility">
          <v-row>
            <v-col md="12" sm="12" xm="12">
              <v-btn block depressed style="color:#fff" color="#0F9750" class="paynow_btn_tdr"
              @click="tdrPayment()"
              > Pay Now </v-btn>
            </v-col>

          </v-row>
                         
       </div>

     <!-- footer section start -->
        <v-footer style="height:50px;margin-top:-10px;background-color:#f2f2f2 !important">
        <v-col md="6" sm="6" xs="6">
          <p 
            class="text-right"
            style="font-size:14px;color:#00592B;font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;"
          >Powered by</p>
        </v-col>

        <v-col md="6" sm="6" xs="6">
          <v-img
            :src="require('./assets/logos/sp_footer_logo.png')"
            alt="ShurjoPay Logo"
            width="77px"
            style="margin-top:-18px;margin-left:-15px"
          >
          </v-img>
        </v-col>
      </v-footer>
      <!-- footer section end -->

      
      </v-card>

    </div>

      <div v-if="isUnauthorized">
          <UnauthorizedCom></UnauthorizedCom>
      </div>

  
    <v-main>
       
    </v-main>
  </v-app>
</template>

<script>
import MyAccount from './components/MyAccount';
import UnauthorizedCom from './components/UnauthorizedCom';
import FastPay from './components/FastPay';
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
const pg_url = require('../pg_url');
import jwt_decode from "jwt-decode";
import BISC from './components/BISC';
export default {
  
  name: 'App',
  components:{
    MyAccount,
    UnauthorizedCom,
    FastPay,
    BISC
  },
  
  data: () => ({
      snackbar: false,
      color: "#0F9750",
      mode: "",
      timeout: 4000,
      popup_text: "",

      // base urls
      base_url: pg_url.base_url,
      image_base_url: pg_url.image_base_url,
      merchant_logo_image_base_url: pg_url.merchant_logo_image_base_url,
      cancel_base_url: pg_url.cancel_base_url,

      merchant_logo:"",
      merchant_name:"",
      customer_name:"Customer Name",

      bkash_img:require('./assets/logos/bkash_mobile.png'),
      pci_dss_img:require('./assets/logos/pci_dss.png'),
      hasNumSaved:false,
      fastPayStatus:false,
      active_tab: '',
      card:false,
      popup_dialog:false,
      colWidth:12,
      is_emi_visible:false,
      checkbox: false,
      toc_checkbox:false,
      selectedTab:null,
      active_bar:null,

      loading_tab:false,
      loading_dialog:false,

      card_tab:true,
      saved_card_tab1:false,
      mobile_banking:false,
      i_banking:false,
      user_account:false,

      login_div:true,
      myaccount:false,
      no_login:true,
      forgot_password_modal:false,
      forgot_password_otp_modal:false,

      active_account_modal:false,
      active_account_mobile:"",
      active_acc_otp:false,
      active_account_otp_input:"",
     
      signup_div:false,
      value: String,
      isFormValid: false,
      other_cards_display:false,
      hideCard_div:true,
      hidecardmenu:true,
      hideshowpaynow:true,
      myPass:"",
      new_password_val:"",
      confirmed_new_password_val:"",

      masterCardActive:false,
      masterCardDeactive:true,
      visaActive:false,
      visaDeactive:true,
      amexActive:false,
      amexDeactive:true,
      unionpayActive:false,
      unionpayDeactive:true,
      dinersActive:false,
      dinersDeactive:true,

      sp_orderid:"",
      sp_amount:"10",
      sp_amount_display:10,
      sp_currency:"BDT",
      sp_token:"",

      sp_return_url:"",
      sp_cancel_url:"",
      
      isMainDiv:false,
      isUnauthorized:false,
      emi_options:"",

      saved_bkash:"",


     editedItem: {
        card_number: "",
        mm: "",
        yyyy:"",
        cvc_cvv: "",
        card_holder_name: "",
        emi: "",
      },

      loginItem:{
        login_mobile_number: "",
        login_password: "",
      },

      signup_items: {
        fullname: "",
        mobile_number: "",
        email_address: "",
        password: "",
      },

      others: {
        mobile_number:"",
      },

       mobileNumberRule: [
          v => !!v || "Mobile number is required",
          v => /^-?\d*$/.test(v)|| "Mobile number can only be numbers",
          v=> /^[0-9]{11}$/.test(v) ||"Mobile number must be 11 digits"
          ],

      mobile_num_sign_up:[
         v => !!v || "Mobile number is required",
          v => /^-?\d*$/.test(v)|| "Mobile number can only be numbers",
          v=> /^[0-9]{11}$/.test(v) ||"Mobile number must be 11 digits"
      ],

       passwordRules: [
         v => !!v || "Password is required",
       ],

       fullnameRules: [
         v => !!v || "Full name is required",
       ],
       
      emailAddressRules: [
         v => !!v || "Email is required",
         v =>/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || "Email address must be valid",
         ],
      emailRulesRegister: [
          v => !!v || 'E-mail is required',
          v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
        ],
      
      cvc_cvvRules:[
        v => !!v || "CVV/CVC is required",
        v=> /^[0-9]{3,4}$/.test(v) ||"CVV/CVC must be at least 3 digits long"
      ],
      CardNumberRules:[
        v => !!v || "Card number is required",
        v => v.length >14 ||'Card number must be at least 13 digits long',
        //v =>  v.isSelectedCard == false || 'Enter a valid card number'
      ],

      forgot_password_mobile:"",
      forgot_password_otp:"",
      saved_card_section:false,
      new_password_modal:false,
      new_password:"",
      confirmed_new_password: "",
      resigtration_otp:false,
      resigter_otp:"",

      user_entered_card_name:"",
      isSelectedCard:false,
      otp_send_text_msg:"",
      
      resigter_otp_text: "",
      resent_otp_text:"Resend OTP",
      display_min:0,
      display_sec:0,
      dispaly_time_otp:true,
      display_resend_otp_text:false,
      active_account_otp_input_text:"",
      
      /*Tabs visibility Dynamic */
      isCard:false,
      isMbanking:false,
      isIBanking:false,

      /*Dynamic Payment Gateways*/
      cards_gateways:"",
      mfs_gateways:"",
      ibanking_gateways:"",

      dwidth:"",

      show_terms_conditions:false,
      toc_dialog:false,
      display_card_number:"",
      store_id:0,
      invoiceno: "",


      AgreementPopupDialog:false,
      agreementStatus:2,

      //token validity
      isValidToken:true,

      // tdr
      is_add_commission:0,
      normal_user:1,

      additional_fees_amt:0,
      payable_amt:0,

      mfs_gateway_id_for_tdr:0,
      gateway_type_id_tdr:0,
      card_name_tdr:"",
      tdr_visibility:0,
      tdrAdditionalFeePopUp:false,

      // isSeletedItem:false,
      // isNormal:true,
      mfs_selected_gateway_iddd:0,
      cards_selected_gateway_iddd:0,
      ibanking_selected_gateway_iddd:0,

      //BISC
      is_bisc_display:false,

  }),

  created(){
    /* 
     *The created hook can be used to run code after an instance is created.
     *In this hook we include the function which need before mounted.
    */
     
  },

  mounted() {
    // this.retrieve_tab();
     this.sp_orderid = this.$route.query.order_id;
     this.sp_token = this.$route.query.token;

    if(typeof(this.sp_token)==='undefined'){
      this.isMainDiv = false;
      this.is_bisc_display = false;
      this.isUnauthorized = true; 
    }
    else{
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
               
                if(response.data.message== 'ok'){

                  var decoded_token = jwt_decode(this.sp_token);
                  var s_id = decoded_token.sub;

                  if(s_id == 530){
                    this.isMainDiv = false;
                    this.is_bisc_display = true;
                    this.isUnauthorized = false;
                  }

                  else{
                    this.isMainDiv = true;
                    this.is_bisc_display = false;
                    this.isUnauthorized = false;
                    this.CheckBackBtnClick();
                    this.check_card_holder_login();
                    setTimeout(this.check_order, 500);
                  }   
                }
               
                else if(response.data.sp_code== '1064'){
                 this.isMainDiv = false;
                 this.is_bisc_display = false;
                 this.isUnauthorized = true; 
                 setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                    this.isMainDiv = false;
                    this.is_bisc_display = false;
                    this.isUnauthorized = true;
                  }
            });
      }

      setTimeout(this.generate_invoice, 600);
      this.UnauthorizedTimeRemaining(15);
      this.check_card_holder_login();
  },

  computed:{
    _seconds:() => 1000,
    _mintues(){
      return this._seconds*60;
    },
     _hours(){
      return this._mintues*60;
    },
  },

  watch:{
   
    "loginItem.login_mobile_number":function(val){
        var length = val.length;
        if(length == 2){
          if(val != '01'){
            this.loginItem.login_mobile_number= "01";
          }
        }
        if(length==11){
          document.getElementById("passwordField").focus();
        }
    },

    //  "others.mobile_number":function(val){
    //     var length = val.length;
    //     if(length == 2){
    //       if(val != '01'){
    //         this.others.mobile_number= "01";
    //       }
    //     }
    //     if(length == 11){
    //       //Agreement dialog
    //       this.agreementMethod();  
    //     }
    // },

    

     "forgot_password_mobile":function(val){
        var length = val.length;
        if(length == 2){
          if(val != '01'){
            this.forgot_password_mobile = "01";
          }
        }
    },
   
    "signup_items.mobile_number":function(val){
       var length = val.length;
        if(length == 2){
          if(val != '01'){
            this.signup_items.mobile_number= "01";
          }
        }
    },


     "editedItem.mm":function(val){
       
        if(val.length ==2){
          var first_digit =parseInt(val.charAt(0));
          var second_digit =parseInt(val.charAt(1));
          if(first_digit == 1){
            if(second_digit == 3 || second_digit ==4 || second_digit==5 || second_digit==6 || second_digit==7 || second_digit==8|| second_digit==9){
               this.editedItem.mm = first_digit;
            }
          }

          if(first_digit == 0){
            if(second_digit == 0){
               this.editedItem.mm = first_digit;
            }
          }
          if(first_digit >1){
            this.editedItem.mm="";
          }
        }
    },

     "editedItem.yyyy":function(val){
       var year_length = val.length;

       if(year_length==2){
         var last_two_digit = new Date().getFullYear().toString().substr(-2);
         var first_two_digit = new Date().getFullYear().toString().substr(0,2);
         var inputed_year = this.editedItem.yyyy;
          if(inputed_year>=last_two_digit){
             this.editedItem.yyyy = first_two_digit + inputed_year;
          }
        }

        if(year_length==4){
        let current_year = new Date().getFullYear().toString();
        let inputed_year = this.editedItem.yyyy;
          if(inputed_year < current_year ){
            this.editedItem.yyyy ="";
          }
          //document.getElementById("cvc_cvv").focus();
       }
     },

    //  "editedItem.cvc_cvv":function(val){
    //    let strNum = val.toString(10)
    //     if(strNum.length==3){
    //        document.getElementById("card_holder_name").focus();
    //    } 
    //  },

     "editedItem.card_number":function(val){
  
          var inputlen = val.length;
          if(inputlen==0 || inputlen ==1){
            this.masterCardDeactive=true;
            this.masterCardActive=false;
            this.visaDeactive=true;
            this.visaActive=false;
            this.amexDeactive=true;
            this.amexActive=false;
            this.unionpayDeactive=true;
            this.unionpayActive=false;
            this.dinersDeactive=true;
            this.dinersActive=false;
            this.isSelectedCard = false;
          }
          
          if(inputlen==2){
            this.select_card_icon(val);
          }

          if(inputlen >11){
            this.select_card_icon(val);
          }
     },
     
     "signup_items.email_address":function (val) {
       var format =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       var validate_email = val.match(format);
       var fetch_id = document.getElementById('email_style');
       if(!validate_email){
         fetch_id.style.color ="#ff5252";
       }
       else{
         fetch_id.style.color ="rgba(0, 0, 0, 0.87)";
       }
     },
        
  },

  methods: {

    
    PCI_DSS(){
      this.saved_card_tab1 = true;
      this.card_tab = false;
    },
    

    
    CheckLogin(){

      if(this.checkbox == true){

        if(localStorage.getItem("mobile_no") === null) {
          this.snackbar = true;
          this.color = "error";
          this.timeout =2000;
          this.popup_text = "Login required to save card";
          
          this.show_account();
          this.login_div = true;
          this.myaccount= false;
          this.no_login=true;
          this.saved_card_tab1 = false;
        }

        else{
            var mobile_no = localStorage.getItem('mobile_no');
            var sp_token_check = this.$route.query.token;
            
            if(mobile_no.length == 11){
              const options = {
              method: 'POST',
              url: this.base_url+'card-holder-login-chk',
              headers: {
                Authorization: 'Bearer '+sp_token_check
              },
              data:{
                mobile_no,
              },
            };
              axios.request(options).then(response =>{
                
                if(response.data.message == 0){
                  this.snackbar = true;
                  this.color = "error";
                  this.timeout =2000;
                  this.popup_text = "Login required to save card";

                  this.show_account();
                  this.login_div = true;
                  this.myaccount= false;
                  this.no_login=true;
                  this.saved_card_tab1 = false;
                }
                
              }).catch(error =>{
                console.log(error);
              });
          }
          else{
            this.snackbar = true;
            this.color = "error";
            this.timeout =2000;
            this.popup_text ="Problem in Mobile Number";
          }

        }
       
        
      }
      
    },
    

    //applied during mounted
    CheckBackBtnClick(){
      var order_id = this.$route.query.order_id;
      var sp_token = this.$route.query.token;
   
      const options = {
        method: 'POST',
        url: this.base_url+'method-verification',
        headers: {
          Authorization: 'Bearer'+ sp_token
        },
        data: {
          order_id
          },
        };

        axios.request(options).then(response =>{
          if(response.data.message==1){
            this.CancelPayment();
          }
          
          }).catch(error =>{
              console.log(error);
          });
    },

    //no need token
    CancelURL(){
       var order_id = this.$route.query.order_id;
       const options = {
          method: 'POST',
          url: this.cancel_base_url+'cancel',
          data: {
            order_id
           },
          };
          axios.request(options).then(response =>{
           if(response.data.message==1){
             window.location.href = response.data.url;
           }
           else{
              this.snackbar = true;
              this.color = "error";
              this.timeout =2000;
              this.popup_text = "Something Went Wrong...";
           }
          }).catch(error =>{
              this.snackbar = true;
              this.color = "error";
              this.timeout =2000;
              this.popup_text = "Something Went Wrong...";
          });
    },

    //token implemented
    CanceledPaymentAPI(){
      var order_id = this.$route.query.order_id;
      var sp_token = this.$route.query.token;
       const options = {
          method: 'POST',
          url: this.base_url+'canceled',
          headers: {
            Authorization: 'Bearer '+ sp_token
          },
          data: {
            order_id
           },
          };

          axios.request(options).then(response =>{
           if(response.data.message==1){
             window.location.href = response.data.url;
           }
           else{
              this.snackbar = true;
              this.color = "error";
              this.timeout =2000;
              this.popup_text = "Something Went Wrong...";
           }
          }).catch(error =>{
              this.snackbar = true;
              this.color = "error";
              this.timeout =2000;
              this.popup_text = "Something Went Wrong...";
          });
    },

    CancelPayment(){
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.message== 'ok'){  
                  this.CanceledPaymentAPI();
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    //later
    agreementYes(){
      this.agreementStatus = 1;
      
      var mobile_no = this.others.mobile_number;
      const options = {
        method: 'POST',
        url: this.base_url+'bkash-cancel-agreement',
        headers: {
           Authorization: 'Bearer '+this.sp_token
        },
        data: {
          mobile_no
        },

        };
         axios.request(options).then(response =>{
           if(response.data.message==1){
              this.snackbar = true;
              this.color = "success";
              this.timeout =2000;
              //this.popup_text = "Agreement cancellation successful";
              this.popup_text = "Agreement creation has cancelled";
              this.AgreementPopupDialog = false;
           }
           else{
              this.AgreementPopupDialog = false;
           }
          
         
        }).catch(error =>{
           console.log(error);
        });
    },

    //later
    agreementMethod(){
      var mobile_no = this.others.mobile_number;
      const options = {
        method: 'POST',
        url: this.base_url+'bkash-agreement-chk',
        headers: {
           Authorization: 'Bearer '+this.sp_token
        },
        data: {
          mobile_no
        },

        };
         axios.request(options).then(response =>{
           if(response.data.message==1){
             this.AgreementPopupDialog = true;
           }
         
        }).catch(error =>{
           console.log(error);
        });

    },

    //no need token
    CloseAgreementPopupDialog(){
      this.agreementStatus = 2;
      this.AgreementPopupDialog = false;
    },

    //no need token
    generate_invoice(){
      
      var date = new Date();
      var ms_part = date.getMilliseconds();
      
      var orderID = this.$route.query.order_id;
      var order_id_part = orderID.substring(0, 3);
      
      const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = ' ';
      const charactersLength = characters.length;
      for ( let i = 0; i <6; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      let random_string = result.trim();
      this.invoiceno = order_id_part+random_string+ms_part;
    },


    showAdditionalFeesPopUp(){
      this.tdrAdditionalFeePopUp = true;
    },

   /**
     * Ibanking Payment
     * 1)With TDR
     * 2)Without TDR
     */

    tdrComissionInfoIBanking(card_name,store_id,gateway_id,gateway_type_id){
      var sp_token = this.$route.query.token;
      const options = {
        method: 'POST',
        url: this.base_url+'get-tdr-charge',
        headers: {
          Authorization: 'Bearer '+ sp_token
        },
        data: {
          store_id,
          gateway_id
          },
        };
        axios.request(options).then(response =>{
          
          var perchange  = response.data[0].tdr_charge;
          
          if(perchange!=0){
            var amount_initiate = Number(this.sp_amount);
            var additional_fees_amount = Number((amount_initiate * (perchange /100)).toFixed(2));
            this.additional_fees_amt = (additional_fees_amount).toFixed(2);
            var payable_amount_bdt = (amount_initiate+additional_fees_amount).toFixed(2);
            this.payable_amt = payable_amount_bdt;

            this.tdr_visibility= this.is_add_commission;
            this.normal_user = 0;

            this.gateway_type_id_tdr = gateway_type_id;
            this.card_name_tdr = card_name;

          }
          else{
            //console.log("No TDR set for that gateway...");
            this.tdr_visibility= 0;
            this.normal_user = 1;
            this.payable_amt = 0;
            this.gateway_type_id_tdr = gateway_type_id;
            this.goDirectIBankingGateway(card_name);
          }
          
        }).catch(error =>{
            console.log(error);
        }); 
    },

    //token implemented
    go_ibanking_gateway(card_name,gateway_id,gateway_type_id){
      this.ibanking_selected_gateway_iddd = gateway_id;
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.message== 'ok'){  
                  this.AssignIBankingGatway(card_name,gateway_id,gateway_type_id);
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    AssignIBankingGatway(card_name,gateway_id,gateway_type_id){

      var store_id = this.store_id;
      var is_add_commission_tdr = this.is_add_commission;
      if(is_add_commission_tdr==1)
      {
        //console.log("is_add_commission enabled");
        this.tdrComissionInfoIBanking(card_name,store_id,gateway_id,gateway_type_id);
      }
      else
      {
       //console.log("No is_add_commission enabled");
       this.goDirectIBankingGateway(card_name);
      }

    },

    goDirectIBankingGateway(card_name){
      if(card_name === ""){
          this.snackbar = true;
          this.color = "red";
          this.timeout = 6000;
          this.popup_text = "No card name is found.";
      }

      if(card_name !== ""){
        var payable_amount2 = this.payable_amt;
        var amount;

        if(payable_amount2>0){
          amount = payable_amount2;
        }
        else{
          amount = this.sp_amount;
        }

        if(amount<10){
          this.snackbar = true;
          this.color = "red";
          this.timeout = 3000;
          this.popup_text = "Amount Cannot be less than 10 Taka.";
        }
        else{
          this.loading_tab = true;
          this.loading_dialog = true;
          var store_id = this.store_id;
          var orderid = this.$route.query.order_id;
          var emi_str = this.editedItem.emi;
          var wordpos = emi_str.search("months");
          var emi_month = emi_str.substring(0, wordpos-1);
          var emi_amt = emi_str.substring(wordpos+7, emi_str.length);
          var sp_token = this.$route.query.token;
          var invoiceno = this.invoiceno;
          const options = {
              method: 'POST',
              url: this.base_url+'only-card-pay',
              headers: {
                Authorization: 'Bearer '+ sp_token
              },
              data:{
                card_name,
                store_id,
                orderid,
                emi_month,
                emi_amt,
                invoiceno
              },
            };
              axios.request(options).then(response =>{
                if(response.data.message==1){
                  this.loading_tab = false;
                  this.loading_dialog = false;
                  window.location.href = response.data.url;
                }
                else{
                  this.loading_tab = false;
                  this.loading_dialog = false;
                  this.snackbar = true;
                  this.color = "red";
                  this.timeout = 3000;
                  this.popup_text = "Something went wrong";
                }

              }).catch(error =>{
                console.log(error);
              }); 
         }
      }

    },


    /**
     * shurcharage/tdr payment action btn
     */

    tdrPayment(){
      var gateway_id = this.mfs_gateway_id_for_tdr;
      var gateway_type_id = this.gateway_type_id_tdr;

      var card_name = this.card_name_tdr;

      if(gateway_type_id==1){
        this.goDirectCardsGateWay(card_name)
      }

      if(gateway_type_id==2){
        this.goDirectMFSGateWay(gateway_id);
      }

      if(gateway_type_id==3){
        this.goDirectIBankingGateway(card_name)
      }
    },

    /**
     * MFS Payment
     * 1)With TDR
     * 2)Without TDR
     */

    tdrComissionInfo(store_id,gateway_id){
       var sp_token = this.$route.query.token;
       const options = {
          method: 'POST',
          url: this.base_url+'get-tdr-charge',
          headers: {
            Authorization: 'Bearer '+ sp_token
          },
          data: {
            store_id,
            gateway_id
           },
          };
          axios.request(options).then(response =>{
            
            var perchange  = response.data[0].tdr_charge;

            if(perchange!=0){

              var amount_initiate = Number(this.sp_amount);
              var additional_fees_amount = Number((amount_initiate * (perchange /100)).toFixed(2));
              
              this.additional_fees_amt = (additional_fees_amount).toFixed(2);
              var payable_amount_bdt = (amount_initiate+additional_fees_amount).toFixed(2);
              this.payable_amt = payable_amount_bdt;

              this.tdr_visibility= this.is_add_commission;
              this.normal_user = 0;

            }
            else{
              this.tdr_visibility= 0;
              this.normal_user = 1;
              this.payable_amt = 0;

              this.goDirectMFSGateWay(gateway_id);

            }
            

          }).catch(error =>{
             console.log(error);
          }); 
    },

    AssignMFSGateway(gateway_id,gateway_type_id){
      // console.log("Gateway ID:"+gateway_id);
      var store_id = this.store_id;
      var is_add_commission_tdr = this.is_add_commission;

      if(is_add_commission_tdr==1)
      {
        if(gateway_id==14){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==16){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==21){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==22){
          //console.log("It's Bkash");
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }

        if(gateway_id==18){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==24){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==23){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        }
        if(gateway_id==25){
          this.tdrComissionInfo(store_id,gateway_id,gateway_type_id);
          this.mfs_gateway_id_for_tdr = gateway_id;
          this.gateway_type_id_tdr = gateway_type_id;
        } 
      }
      else
      {
       //console.log("No is_add_commission setted");
       this.goDirectMFSGateWay(gateway_id);
      }

    },

    goDirectMFSGateWay(gateway_id){
       if(gateway_id==14){
          this.ROCKET();
        }
        if(gateway_id==16){
          this.MCASH();
        }
        if(gateway_id==21){
          this.NAGAD();
        }
        if(gateway_id==22){
          this.BKASH();
        }

        if(gateway_id==18){
          this.TCASH();
        }
        if(gateway_id==24){
          this.OKWALLET();
        }
        if(gateway_id==23){
          this.UPAY();
        }
        if(gateway_id==25){
          this.SHURECASH();
        } 
    },

    go_mfs_gateway(gateway_id,gateway_type_id) {

      this.mfs_selected_gateway_iddd = gateway_id;
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.message== 'ok'){  
                  this.AssignMFSGateway(gateway_id,gateway_type_id);
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
     
    },



    //no need token
    showRemaining(total){

      var t = new Date();
      var getMin = t.getMinutes();
      var getSec = t.getSeconds();
      var getMiliSec = t.getMilliseconds();
      
      const timer = setInterval(() =>{
      const now = new Date();
      var getYear = now.getFullYear();
      var getMonth = now .getMonth();
      var getDate = now.getDate();
      var getHour = now.getHours();
      
      const end = new Date(getYear,getMonth,getDate,getHour,getMin+total,getSec,getMiliSec);
        const distance = end.getTime() - now.getTime();
        if(distance<0){
          this.dispaly_time_otp = false;
          this.display_resend_otp_text =true;
        clearInterval(timer);
        return
        }
        const minutes = Math.floor((distance % this._hours) / this._mintues);
        const seconds = Math.floor((distance % this._mintues) / this._seconds);

        this.display_min = minutes < 10 ? '0' + minutes : minutes;
        this.display_sec = seconds < 10 ? '0' + seconds : seconds;
      },1000);
    },

    //no need token
    UnauthorizedTimeRemaining(timeInMinutes){
      var t = new Date();
      var getMin = t.getMinutes();
      var getSec = t.getSeconds();
      var getMiliSec = t.getMilliseconds();
      
      const timer = setInterval(() =>{
      const now = new Date();
      var getYear = now.getFullYear();
      var getMonth = now .getMonth();
      var getDate = now.getDate();
      var getHour = now.getHours();
      
      const end = new Date(getYear,getMonth,getDate,getHour,getMin+timeInMinutes,getSec,getMiliSec);
        const distance = end.getTime() - now.getTime();
        if(distance<0){
          this.isMainDiv = false;
          this.is_bisc_display = false;
          this.isUnauthorized = true;
          //window.location.href = this.sp_cancel_url;
          this.CancelURL();
        clearInterval(timer);
        return
        }
      },1000);
    },

    //later
    bkash_num_list(){
       var sp_token = this.$route.query.token;
       var mobile_no = localStorage.getItem("mobile_no");
       const options = {
          method: 'POST',
          url: this.base_url+'bkash-mobile-list',
          headers: {
            Authorization: 'Bearer '+ sp_token
          },
          data: {
            mobile_no
           },
          };
          axios.request(options).then(response =>{
            if(response.data.message ==2){
                this.hasNumSaved = false;
            }
            else{
               this.saved_bkash = response.data;
               this.hasNumSaved = true;
            }
          }).catch(error =>{
             console.log(error);
          });
    },

    //checked during render
    check_order(){
       var orderid = this.$route.query.order_id;
       var sp_token = this.$route.query.token;
       const options = {
          method: 'POST',
          url: this.base_url+'chk-order',
          headers: {
            Authorization: 'Bearer '+ sp_token
          },
          data: {
            orderid
           },
          };

          axios.request(options).then(response =>{
            this.merchant_info(response.data[0].store_id);
            this.sp_amount = response.data[0].payable_amount;
           
            this.sp_amount_display = parseFloat(response.data[0].payable_amount).toFixed(2);
            
            this.sp_currency = response.data[0].currency;
            this.store_id = response.data[0].store_id;
            this.sp_return_url = response.data[0].return_url;
            this.sp_cancel_url = response.data[0].cancel_url;
          }).catch(error =>{
             console.log(error);
          });
    },

    //checked during render within check_order method
    merchant_info(store_idd){
       var store_id = store_idd;
       var sp_token = this.$route.query.token;
       const options = {
          method: 'POST',
          url: this.base_url+'get-merchant-info',
          headers: {
            Authorization: 'Bearer '+ sp_token
          },
          data: {
            store_id
           },
          };
          axios.request(options).then(response =>{
            this.is_add_commission = response.data[0].is_add_commission;
            var CardVisibility = response.data[0].is_cards;
            var MbankingVisibility = response.data[0].is_mbanking; 
            var IBnakingVisibility = response.data[0].is_ibanking;
            this.isCard = CardVisibility;
            this.isMbanking = MbankingVisibility;
            this.isIBanking = IBnakingVisibility;
            this.merchant_name = response.data[0].merchant_name;
            if(response.data[0].is_emi ==1 && this.sp_amount>=10000){
                 this.is_emi_visible = true;
                 this.colWidth = 6;
                 this.setEMI(this.sp_amount); 
            }
            /**
             * If no merchnat logo is set for merchnat than shows this
             */
            if(response.data[0].store_logo === null){
              this.merchant_logo = require('./assets/test_merchant_logos/shurjopay_logo.png');
            }

            /**
             * If merchnat logo is set for merchnat than shows this
             */
            if(response.data[0].store_logo !== null){
              this.merchant_logo = this.merchant_logo_image_base_url + response.data[0].store_logo;
            }

            
            /*Active Tabs Logic*/
            var tabs_val = [CardVisibility, MbankingVisibility,IBnakingVisibility];
            var f_tabs = [];
            var j=0;
            for (let i = 0; i < tabs_val.length; i++) {
              const element = tabs_val[i];
              if(element !==0){
                f_tabs[j] = tabs_val[i];
                j++;
              }
            }
            this.active_tabs(f_tabs[0]);
           

            var counter=0;
            for (let i = 1; i <=3; i++) {
              if(CardVisibility == i || MbankingVisibility == i || IBnakingVisibility == i){
                counter++;
              }
            }
            if(counter==3){
              this.dwidth = "width:25%";
            }

            if(counter==2){
              this.dwidth = "width:33.33%";
            }
           
            if(counter==1){
              this.dwidth ="width:50%"
            }
           
          }).catch(error =>{
             console.log(error);
          });
    },

    //no  need token
    setEMI(amount){

      var setemi_options = [];
      var i;
      for (i = 1; i <=5; i++) {
        if(i==5){
          setemi_options.push({
          id: 5,
          option_val:'18 months -BDT '+(amount/18).toFixed(2)+ '/Month' ,
          select_val:'18 months '+(amount/18).toFixed(2),
        });
        }
        else{
           setemi_options.push({
            id: i*3,
            option_val: i*3+' months -BDT '+(amount/(i*3)).toFixed(2)+ '/Month' ,
            select_val:i*3+' months '+(amount/(i*3)).toFixed(2),
          });
        }
      }
      this.emi_options = setemi_options;

    },


    //token implemented
    RetrieveMerchantGateway(store_id,gateway_type_id){
      var sp_token = this.$route.query.token;
      const options = {
        method: 'POST',
        url: this.base_url+'get-merchant-gateway',
        headers: {
          Authorization: 'Bearer '+ sp_token
        },
        data:{
          store_id,
          gateway_type_id
        },

      };

      axios.request(options).then(response =>{
        if(gateway_type_id==1){
        this.cards_gateways = response.data;
        }
        if(gateway_type_id == 2){
          this.mfs_gateways = response.data;
        }
        if(gateway_type_id == 3){
          this.ibanking_gateways = response.data;
        }
        
      }).catch(error =>{
          console.log(error);
      });
    },
     
    merchant_gateway(store_id,gateway_type_id){

      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.message== 'ok'){  
                  this.RetrieveMerchantGateway(store_id,gateway_type_id);
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    

    //later
    save_card_info(){
        
        var emi_str = this.editedItem.emi;
        var wordpos = emi_str.search("months");
        var emi_month = emi_str.substring(0, wordpos-1);
        var emi_amt = emi_str.substring(wordpos+7, emi_str.length);
        let selectedCardStatus = this.isSelectedCard;

        if(selectedCardStatus === true){
          
            var sp_login_status = localStorage.getItem('isLoggedin');
            var token = this.$route.query.token;
            var orderid = this.$route.query.order_id;
            var amount = this.sp_amount;
            var card_number1 = this.editedItem.card_number;
            var card_number = card_number1.replace(/ /g, "");
            var exmonth = this.editedItem.mm;
            var exyear = this.editedItem.yyyy;
            var cvv_cvc = (this.editedItem.cvc_cvv).toString();
            var card_holder_name = this.editedItem.card_holder_name;
            var mobile_no = localStorage.getItem('mobile_no');

            var card_name = this.user_entered_card_name;
            var is_save_card;
            var store_id = this.store_id;
            
            if(this.checkbox == true){
              is_save_card ='1';
            }
            else{
              is_save_card ='0';
            }


            /*
            **if the user is not logged in then....
            **isLoggedin value is null
            **mobile_no value is null
            */
            if(sp_login_status===null){
              const options = {
                method: 'POST',
                url: this.base_url+'save-card-info',
                headers: {
                  Authorization: 'Bearer '+ token
                },
                data: {
                  card_number,
                  exmonth,
                  exyear,
                  cvv_cvc,
                  card_holder_name,
                  card_name,
                  orderid,
                  amount,
                  is_save_card,
                  emi_amt,
                  emi_month,
                  store_id,
                },
                
                };

                axios.request(options).then(response =>{
                  if(response.data.message == 2){
                    window.location.href = response.data.url;
                  }
                  else if(response.data.message == 0){
                    this.snackbar = true;
                    this.color = "red";
                    this.timeout =2000;
                    this.popup_text = "Something went wrong.";
                  }

                  else if(response.data.message == 3){
                    this.snackbar = true;
                    this.color = "red";
                    this.timeout =2000;
                    this.popup_text = "Your Order id not found.Please checkout your process.";
                  }
            
                  }).catch(error =>{
                    console.log(error);
                  });
            }
            //sp_login_status null if end


            /*
            * when sp_login_status is set 
            */
            
            if(sp_login_status !== null){

              if(sp_login_status==1){
                const options = {
                  method: 'POST',
                  url: this.base_url+'save-card-info',
                  headers: {
                    Authorization: 'Bearer '+ token
                  },
                  data: {
                    card_number,
                    exmonth,
                    exyear,
                    cvv_cvc,
                    card_holder_name,
                    mobile_no,
                    card_name,
                    orderid,
                    amount,
                    is_save_card,
                    emi_amt,
                    emi_month,
                    store_id,
                  },
                  
                  };

                axios.request(options).then(response =>{
                  if(response.data.message == 1){
                    this.snackbar = true;
                    this.color = "#0F9750";
                    this.timeout =2000;
                    this.popup_text = "Card saved successful.";
                    window.location.href = response.data.url;
                  }

                  else if(response.data.message == 2){
                    window.location.href = response.data.url;
                  }
                  else if(response.data.message == 0){
                    this.snackbar = true;
                    this.color = "red";
                    this.timeout =2000;
                    this.popup_text = "Something went wrong.";
                  }

                  else if(response.data.message == 3){
                    this.snackbar = true;
                    this.color = "red";
                    this.timeout =2000;
                    this.popup_text = "Your Order id not found.Please checkout your process.";
                  }
            
                  }).catch(error =>{
                    console.log(error);
                  });
              } 
              //sp_login_status 1 if end

              else{
                this.snackbar = true;
                this.color = "red";
                this.timeout =2000;
                this.popup_text = "You changed the isLoggedin value";
              }


          }
          //sp_login_status is not null if end

        } //selectedCardStatus if end

        else if(selectedCardStatus === false){
          this.snackbar = true;
          this.color = "red";
          this.timeout = 3000;
          this.popup_text = "Enter a Valid Card number";
          this.editedItem.card_number="";
        }  
    },

    openCardForm(){
      this.saved_card_tab1 = true;
      this.card_tab = false;
    },



    /**
     * Cards Payment
     * 1)With TDR
     * 2)Without TDR
     */

    tdrComissionInfoCards(card_name,store_id,gateway_id,gateway_type_id){
      var sp_token = this.$route.query.token;
      const options = {
        method: 'POST',
        url: this.base_url+'get-tdr-charge',
        headers: {
          Authorization: 'Bearer '+ sp_token
        },
        data: {
          store_id,
          gateway_id
          },
        };
        axios.request(options).then(response =>{
          
          var perchange  = response.data[0].tdr_charge;
          
          if(perchange!=0){
            
            var amount_initiate = Number(this.sp_amount);
            var additional_fees_amount = Number((amount_initiate * (perchange /100)).toFixed(2));
            this.additional_fees_amt = (additional_fees_amount).toFixed(2);
            var payable_amount_bdt = (amount_initiate+additional_fees_amount).toFixed(2);
            this.payable_amt = payable_amount_bdt;

            this.tdr_visibility= this.is_add_commission;
            this.normal_user = 0;

            this.gateway_type_id_tdr = gateway_type_id;
            this.card_name_tdr = card_name;

          }
          else{
            //console.log("No TDR set for that gateway...");
            this.tdr_visibility= 0;
            this.normal_user = 1;
            this.payable_amt = 0;
            this.gateway_type_id_tdr = gateway_type_id;
            this.goDirectCardsGateWay(card_name);
          }
          
        }).catch(error =>{
            console.log(error);
        }); 
    },
    
    ForwardCardGateway(card_name,gateway_id,gateway_type_id){
      var store_id = this.store_id;
      var is_add_commission_tdr = this.is_add_commission;
      if(is_add_commission_tdr==1)
      {
        //console.log("is_add_commission enabled");
        this.tdrComissionInfoCards(card_name,store_id,gateway_id,gateway_type_id);
      }
      else
      {
       //console.log("No is_add_commission enabled");
       this.goDirectCardsGateWay(card_name);
      }
    },

    goDirectCardsGateWay(card_name){
      if(card_name === ""){
          this.snackbar = true;
          this.color = "red";
          this.timeout = 6000;
          this.popup_text = "No card name is found.";
      }
      if(card_name !== ""){

        var payable_amount2 = this.payable_amt;
        var amount;

        if(payable_amount2>0){
          amount = payable_amount2;
        }
        else{
          amount = this.sp_amount;
        }

        if(amount<10){
          this.snackbar = true;
          this.color = "red";
          this.timeout = 3000;
          this.popup_text = "Amount Cannot be less than 10 Taka.";
        }
        else{
          this.loading_tab = true;
          this.loading_dialog = true;
          var store_id = this.store_id;
          var orderid = this.$route.query.order_id;
          var emi_str = this.editedItem.emi;
          var wordpos = emi_str.search("months");
          var emi_month = emi_str.substring(0, wordpos-1);
          var emi_amt = emi_str.substring(wordpos+7, emi_str.length);
          var sp_token = this.$route.query.token;
          var invoiceno = this.invoiceno;
          const options = {
              method: 'POST',
              url: this.base_url+'only-card-pay',
              headers: {
                Authorization: 'Bearer '+ sp_token
              },
              data:{
                card_name,
                store_id,
                orderid,
                emi_month,
                emi_amt,
                invoiceno
              },
            };
              axios.request(options).then(response =>{
                if(response.data.message==1){
                  this.loading_tab = false;
                  this.loading_dialog = false;
                  window.location.href = response.data.url;
                }
                else{
                  this.loading_tab = false;
                  this.loading_dialog = false;
                  this.snackbar = true;
                  this.color = "red";
                  this.timeout = 3000;
                  this.popup_text = "Something went wrong";
                }
              }).catch(error =>{
                console.log(error);
              }); 
         }
      }
    },

    goCardGateway(card_name,gateway_id,gateway_type_id){
     this.cards_selected_gateway_iddd = gateway_id;
     this.sp_orderid = this.$route.query.order_id;
     this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined')
      {
          this.isMainDiv = false;
          this.isUnauthorized = true;
      }

      else
      {
          const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };

            axios.request(options).then(response =>{
              if(response.data.message== 'ok'){  
                  this.ForwardCardGateway(card_name,gateway_id,gateway_type_id);
              }
              
              else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  setTimeout(this.CancelURL, 500);
              }
              
              }).catch(error =>{
                if (error.response.status == 401) {
                    this.isMainDiv = false;
                    this.isUnauthorized = true;
                    setTimeout(this.CancelURL, 500);
                  }
            });
        }
    },


    //later
    tabIndex(index){
      window.localStorage.setItem('sp_active_tab',index);
    },

    // retrieve_tab(){
    //   var restore_tab = window.localStorage.getItem('sp_active_tab');

    //   if(restore_tab==0){
    //     this.active_tab = 'cards';
    //     this.show_cards();
    //   }
      
    //   else if(restore_tab == 1){
    //     this.active_tab = 'mfs';
    //     this.show_mbanking();
    //   }
    //   else if(restore_tab == 2){
    //     this.active_tab = 'ibanking';
    //     this.show_ibanking();
    //   }
    //   else if(restore_tab == 3){
    //     this.active_tab = 'account';
    //     this.show_account();
    //   }
    //   else if(restore_tab == 4){
    //     this.active_tab = 'cards';
    //     this.othersCard();
    //   }
    //   else{
    //     this.active_tab = 'cards';
    //     this.show_cards();
    //   }
    //   }

    //   this.active_tab = 'cards';
    //   this.show_account();
    // },

    


    //later
    active_tabs(index){
      if(index==1){
        this.active_tab = 'cards';
        this.show_cards();
      }
      else if(index==2){
        this.active_tab = 'mfs';
        this.show_mbanking();
      }
      else if(index==3){
        this.active_tab = 'ibanking';
        this.show_ibanking();
      }
      else{
        this.active_tab = 'cards';
        this.show_account();
      }
    },

    //checked when clicked
    BKASH(){

      // var payable_amount2 = this.payable_amt;
      // var amount;

      // if(payable_amount2>0){
      //   amount = payable_amount2;
      // }
      // else{
      //   amount = this.sp_amount;
      // }
      // //console.log("Enter Bkash");
      // if(amount<1){
      //   this.snackbar = true;
      //   this.color = "red";
      //   this.timeout = 3000;
      //   this.popup_text = "Amount Cannot be less than 1 Taka";
      // }

      // else{
      //   if(this.myaccount === true){
      //     this.bkash_num_list();
      //   }
      //   this.popup_dialog =true;
      //   this.loading_tab=false;
      //   this.loading_dialog=false;

      // }

      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }
      
      if(amount<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount cannot be less than 1 Taka";
      }

      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;
        const options = {
          method: 'POST',
          url: this.base_url+'bkash-pay-now',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            orderid,
            amount,
            store_id,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
             this.loading_tab = false;
             this.loading_dialog = false;
             window.location.href = response.data.burl;
          }).catch(error =>{
              this.loading_tab = false;
              this.loading_dialog = false;
              this.snackbar = true;
              this.color = "red";
              this.timeout = 3000;
              this.popup_text = "Something went wrong.";
              console.log(error);
          });
        }

    },

    //no need
    closePopup(){
      this.popup_dialog =false;
      this.$refs.form3.reset();
    },

    //checked when clicked
    send_bkh(){
      let checkVal= this.$refs.form3.validate();
      var agreement_cancle= this.agreementStatus;
      
      if(checkVal){
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;

        var payable_amount2 = this.payable_amt;
        var amount;

        if(payable_amount2>0){
          amount = payable_amount2;
        }
        else{
          amount = this.sp_amount;
        }
        var mobile_no = this.others.mobile_number;
        var mobile_number = localStorage.getItem('mobile_no');
        var bankPara = "bkh";
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;
        

        if(mobile_number===null){
          const options = {
            method: 'POST',
            url: this.base_url+ 'bkash-pay-now',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            },
            data: {
              orderid,
              amount,
              bankPara,
              mobile_no,
              store_id,
              invoiceno,
              agreement_cancle
            },
            
            };
            axios.request(options).then(response =>{
              window.location.href = response.data.burl;
              this.$refs.form3.reset();
              this.popup_dialog=false;
              this.loading_tab=false;
              this.loading_dialog=false;
            }).catch(error =>{
              console.log(error);
              this.$refs.form3.reset();
              this.popup_dialog=false;
              this.loading_tab=false;
              this.loading_dialog=false;
            });
        }

        if(mobile_number !==null){
            const options = {
            method: 'POST',
            url: this.base_url+ 'bkash-pay-now',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            },
            data: {
              orderid,
              amount,
              bankPara,
              mobile_no,
              mobile_number,
              store_id,
              invoiceno,
              agreement_cancle
            },
            
            };
            axios.request(options).then(response =>{
              window.location.href = response.data.burl;
              this.$refs.form3.reset();
              this.popup_dialog=false;
              this.loading_tab=false;
              this.loading_dialog=false;
            }).catch(error =>{
              console.log(error);
              this.$refs.form3.reset();
              this.popup_dialog=false;
              this.loading_tab=false;
              this.loading_dialog=false;
            });
        }

      }
    },

   //checked when clicked
    NAGAD(){
      var payable_amount2 = this.payable_amt;
      var amt;

      if(payable_amount2>0){
        amt = payable_amount2;
      }
      else{
        amt = this.sp_amount;
      }
      

      if(amt<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }

      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;
        const options = {
          method: 'POST',
          url: this.base_url+'nagad-pay',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            orderid,
            amt,
            store_id,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
             this.loading_tab = false;
             this.loading_dialog = false;
             window.location.href = response.data.nurl;
          }).catch(error =>{
              this.loading_tab = false;
              this.loading_dialog = false;
              this.snackbar = true;
              this.color = "red";
              this.timeout = 3000;
              this.popup_text = "Something went wrong.";
              console.log(error);
          });
      }

    },

    //checked when clicked
    MCASH(){
      var payable_amount2 = this.payable_amt;
      var amt;

      if(payable_amount2>0){
        amt = payable_amount2;
      }
      else{
        amt = this.sp_amount;
      }
     

      if(amt<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }

      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;

        const options = {
          method: 'POST',
          url: this.base_url+'mcash-pay-amt',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            amt,
            orderid,
            store_id,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            window.location.href = response.data.mburl;
          }).catch(error =>{
              this.loading_tab = false;
              this.loading_dialog = false;
              this.snackbar = true;
              this.color = "red";
              this.timeout = 3000;
              this.popup_text = "Something went wrong.";
              console.log(error);
          });
      }
      
    },
    
    //checked when clicked
    TCASH(){

      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }
      

      if(amount<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }
      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;
        var card_name ="tap";  
        const options = {
          method: 'POST',
          url: this.base_url+'only-card-pay',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            amount,
            orderid,
            store_id,
            invoiceno,
            card_name
          },

          };
          axios.request(options).then(response =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            window.location.href = response.data.url;
          }).catch(error =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            this.snackbar = true;
            this.color = "red";
            this.timeout = 3000;
            this.popup_text = "Something went wrong.";
            console.log(error);
          });
      }
    },

    //checked when clicked
    UPAY(){
      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }


      if(amount<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }
      else{
          this.loading_tab=true;
          this.loading_dialog=true;
          var orderid = this.$route.query.order_id;
          
          var bankPara = "upay";
          var invoiceno = this.invoiceno;
          var store_id = this.store_id;
          const options = {
            method: 'POST',
            url: this.base_url+'upay-payment',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            },
            data: {
              orderid,
              amount,
              bankPara,
              store_id,
              invoiceno
            },

            };
            axios.request(options).then(response =>{
              this.loading_tab = false;
              this.loading_dialog = false;
              window.location.href = response.data.upurl;
            }).catch(error =>{
              this.loading_tab = false;
              this.loading_dialog = false;
              this.snackbar = true;
              this.color = "red";
              this.timeout = 3000;
              this.popup_text = "Something went wrong.";
              console.log(error);
            });
        }
     
    },
    
    //checked when clicked
   SHURECASH(){
      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }

      if(amount<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }
      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var bankPara = "scash";
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;

        const options = {
          method: 'POST',
          url: this.base_url+'sure-cash-amt',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            orderid,
            amount,
            bankPara,
            store_id,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            window.location.href = response.data.scurl;
          }).catch(error =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            this.snackbar = true;
            this.color = "red";
            this.timeout = 3000;
            this.popup_text = "Something went wrong.";
            console.log(error);
          });
      }
    },

  //checked when clicked
  OKWALLET(){
      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }
      

      if(amount<1){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 1 Taka";
      }
      else{
        this.loading_tab=true;
        this.loading_dialog=true;
        var orderid = this.$route.query.order_id;
        var bankPara = "ok-wallet";
        var invoiceno = this.invoiceno;
        var store_id = this.store_id;
        const options = {
          method: 'POST',
          url: this.base_url+'ok-wallet-amt',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            orderid,
            amount,
            bankPara,
            store_id,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            window.location.href = response.data.ourl;
          }).catch(error =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            this.snackbar = true;
            this.color = "red";
            this.timeout = 3000;
            this.popup_text = "Something went wrong.";
            console.log(error);
          });
      }
    },

//checked when clicked
  ROCKET(){
      var payable_amount2 = this.payable_amt;
      var amount;

      if(payable_amount2>0){
        amount = payable_amount2;
      }
      else{
        amount = this.sp_amount;
      }
      

      if(amount<10){
        this.snackbar = true;
        this.color = "red";
        this.timeout = 3000;
        this.popup_text = "Amount Cannot be less than 10 Taka.";
      }

      else{
        this.loading_tab = true;
        this.loading_dialog = true;
        var orderid = this.$route.query.order_id;
        var store_id = this.store_id;
        var card_name = "rocket";
        var invoiceno = this.invoiceno;

        const options = {
          method: 'POST',
          url: this.base_url+'only-card-pay',
          headers: {
            Authorization: 'Bearer '+this.sp_token
          },
          data: {
            orderid,
            store_id,
            card_name,
            amount,
            invoiceno
          },

          };
          axios.request(options).then(response =>{
            if(response.data.message == 1){
               this.loading_tab = false;
               this.loading_dialog = false;
                window.location.href = response.data.url;
            }
            else{
                this.loading_tab = false;
                this.loading_dialog = false;
                this.snackbar = true;
                this.color = "red";
                this.timeout = 3000;
                this.popup_text = "Something went wrong.";
            }
          }).catch(error =>{
            this.loading_tab = false;
            this.loading_dialog = false;
            console.log(error);
          });
      }  
  },

    //checked when clicked
    show_cards(){
      this.card_tab=true;
      this.mobile_banking=false;
      this.i_banking=false;
      this.user_account=false;
      this.loading_tab=false;
      this.loading_dialog=false;

      this.other_cards_display=false;
      this.hideCard_div=true;
      this.hidecardmenu=true;
      this.hideshowpaynow=true;
      this.saved_card_tab1 =false;
      this.fastPayStatus = true;
      this.saved_card_section =false;
      var passStoreID = this.store_id;

      this.tdr_visibility= 0;
      this.normal_user = 1;
      this.payable_amt = 0;

      this.merchant_gateway(passStoreID,1);

      this.cards_selected_gateway_iddd=0;
      this.mfs_selected_gateway_iddd=0;
      this.ibanking_selected_gateway_iddd=0;
    },

     //checked when clicked
    show_mbanking(){
      this.card_tab=false;
      this.mobile_banking=true;
      this.i_banking=false;
      this.user_account=false;
      this.loading_tab=false;
      this.loading_dialog=false;
      this.saved_card_tab1 =false;
      var passStoreID = this.store_id;
      
      this.tdr_visibility= 0;
      this.normal_user = 1;
      this.payable_amt = 0;

      this.merchant_gateway(passStoreID,2);

      this.cards_selected_gateway_iddd=0;
      this.mfs_selected_gateway_iddd=0;
      this.ibanking_selected_gateway_iddd=0;
    },

     //checked when clicked
    show_ibanking(){
      this.card_tab=false;
      this.mobile_banking=false;
      this.i_banking=true;
      this.user_account=false;
      this.loading_tab=false;
      this.loading_dialog=false;
      this.saved_card_tab1 =false;
      var passStoreID = this.store_id;

      this.tdr_visibility= 0;
      this.normal_user = 1;
      this.payable_amt = 0;

      this.merchant_gateway(passStoreID,3);

      this.cards_selected_gateway_iddd=0;
      this.mfs_selected_gateway_iddd=0;
      this.ibanking_selected_gateway_iddd=0;
    },

   
    //Token implemented
    show_account(){

      this.cards_selected_gateway_iddd=0;
      this.mfs_selected_gateway_iddd=0;
      this.ibanking_selected_gateway_iddd=0;

      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.message== 'ok'){  
                  this.card_tab=false;
                  this.mobile_banking=false;
                  this.i_banking=false;
                  this.loading_tab=false;
                  this.loading_dialog=false;
                  this.saved_card_tab1 =false;
                  this.signup_div= false;
                  this.user_account=true;
                  this.login_div = true;

                  this.tdr_visibility= 0;
                  this.normal_user = 1;
                  this.payable_amt = 0;

                  if(this.myaccount == true){
                    this.login_div = false;
                    this.myaccount = true;
                  }
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    //card-holder-login check api
    login_status(){
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.message== 'ok'){  
                  this.login_status_pre();
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },
    
    login_status_pre(){
       let check_login_val= this.$refs.form5.validate();
       if(check_login_val){
         var token = this.$route.query.token;
         var mobile_no = this.loginItem.login_mobile_number;
         var ch_password =this.loginItem.login_password;
          const options = {
            method: 'POST',
            url: this.base_url+'card-holder-login',
            headers: {
              Authorization: 'Bearer '+token
            },
            data: {
              mobile_no,
              ch_password,
            },
          
          };

          axios.request(options).then(response =>{
            //When the user is active and successful login
            if(response.data.message == 1){
              this.customer_name = response.data.customer_name;
              this.login_div = false;
              this.myaccount= true;
              this.no_login=false;
              localStorage.setItem("isLoggedin", "1");
              localStorage.setItem("mobile_no", mobile_no);
              
              this.loginItem.login_mobile_number="";
              this.loginItem.login_password="";
             // window.location.reload();
              
            }
            //Password not matched
            else if(response.data.message == 2){
              this.snackbar = true;
              this.color = "error";
              this.timeout = 6000;
              this.popup_text = "Wrong mobile number or password.";
            }

            //no such account exist
            else if(response.data.message == 3){
              this.snackbar = true;
              this.color = "error";
              this.timeout = 6000;
              this.popup_text = "We couldn't find your account.";
             }
            //password matched but account is not active
            else if(response.data.message == 0){
              this.login_div=true;
              this.active_account_modal = true;
              this.active_account_mobile = this.loginItem.login_mobile_number;
             }
          
        }).catch(error =>{
             console.log(error);
             this.snackbar = true;
             this.color = "red";
             this.popup_text = "Something went wrong...";
        });
        
       }
    },

    //checked during mounted
    check_card_holder_login(){

      if(localStorage.getItem("mobile_no") === null) {
        console.log("[ShurjoPay] You are not login user.");
      }

      else{
          var mobile_no = localStorage.getItem('mobile_no');
          var sp_token_check = this.$route.query.token;
          
          if(mobile_no.length == 11){
          const options = {
          method: 'POST',
          url: this.base_url+'card-holder-login-chk',
          headers: {
            Authorization: 'Bearer '+sp_token_check
          },
          data:{
            mobile_no,
          },
        };
          axios.request(options).then(response =>{
            if(response.data.message == 1){
              this.login_div = false;
              this.myaccount= true;
              this.no_login=false;
              this.customer_name = response.data.customer_name;
            }
            else if(response.data.message == 0){
              this.login_div = true;
              this.myaccount= false;
              this.no_login=true;
            }
            
          }).catch(error =>{
             console.log(error);
          });

        } 

      }
       
    },

    //token applied
    register(){
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.message== 'ok'){  
                  this.register_pre();
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    register_pre(){
       let signup_val= this.$refs.form_register.validate();
       if(signup_val){
        var email = this.signup_items.email_address;
        var token = this.$route.query.token;
        var name = this.signup_items.fullname;
        var password = this.signup_items.password;
        var password_confirmation = password;
        var mobile_no = this.signup_items.mobile_number;
        const options = {
          method: 'POST',
          url: this.base_url+'card-user-register',
          headers: {
            Authorization: 'Bearer '+token
          },
          data: {
            name,
            email,
            password,
            password_confirmation,
            mobile_no
          },
          
          };
          axios.request(options).then(response =>{
            if(response.data.message == 1){
                
                this.signup_div = false;
                this.login_div = true;
                this.resigtration_otp = true;

                var inputed_mobile = this.signup_items.mobile_number;
                var f_part = inputed_mobile.substring(0,3);
                var first_part = inputed_mobile.substring(3, 9);
                var hidden_part = first_part.replace(/[0-9]/g, "*");
                var normal_part = inputed_mobile.substring(9, 11);
                this.resigter_otp_text = f_part+ hidden_part + normal_part ;
                this.showRemaining(2);
            }

            else if(response.data.message == 0){
               this.snackbar = true;
               this.color = "red";
               this.timeout = 7000;
               this.popup_text = "This "+ mobile_no + " number is already used.";
            }
          }).catch(error =>{
             console.log(error);
             this.snackbar = true;
             this.color = "red";
             this.timeout = 6000;
             this.popup_text = "Something went wrong...";
          });
       }
    },


    //token applied self
    register_otp_confirm(){
       let check_otp_varification= this.$refs.register_otpp.validate();
       if(check_otp_varification){
        var token = this.$route.query.token;
        var mobile_no = this.signup_items.mobile_number;
        var verify_code = this.resigter_otp;
  
         const options = {
          method: 'POST',
          url: this.base_url+'verify-account',
          headers: {
            Authorization: 'Bearer '+token
          },
          data: {
            mobile_no,
            verify_code,
          },
          
          };

           axios.request(options).then(response =>{
            if(response.data.message == 1){
                this.snackbar = true;
                this.color = "#0F9750";
                this.popup_text = "Account Activation successful.";
                this.login_div=true;
                this.signup_div=false;
                this.resigtration_otp = false;  
            }
            else if(response.data.message == 0){
               this.snackbar = true;
               this.color = "red";
               this.popup_text = "OTP does not matched.";
            }
            else if(response.data.sp_token == '1064'){
              this.isMainDiv = false;
              this.isUnauthorized = true;
              this.CancelURL();
            }
           
          }).catch(error =>{
             console.log(error);
          });
       }
    },
   
    //token applied
    close_reg_otp_modal(){
       this.sp_orderid = this.$route.query.order_id;
        this.sp_token = this.$route.query.token;

        if(typeof(this.sp_token)==='undefined'){
          this.isMainDiv = false;
          this.isUnauthorized = true;
        }

      else{
          const options = {
              method: 'POST',
              url: this.base_url+'chk_token',
              headers: {
                Authorization: 'Bearer '+this.sp_token
              }
            };
              axios.request(options).then(response =>{
                  if(response.data.message== 'ok'){  
                    this.signup_div = true;
                    this.login_div = false;
                    this.resigtration_otp = false;
                  }
                
                  else if(response.data.sp_code== '1064'){
                    this.isMainDiv = false;
                    this.isUnauthorized = true;
                    setTimeout(this.CancelURL, 500);
                  }
                
              }).catch(error =>{
                  if (error.response.status == 401) {
                    this.isMainDiv = false;
                    this.isUnauthorized = true;
                    setTimeout(this.CancelURL, 500);
                    }
            });
        }
    },

    //token applied self
    resend_otp(){
        var token = this.$route.query.token;
        var mobile_no = this.signup_items.mobile_number;
         const options = {
          method: 'POST',
          url: this.base_url+'resend-verification-code',
          headers: {
            Authorization: 'Bearer '+token
          },
          data:{
            mobile_no
          },
          };

           axios.request(options).then(response =>{
            if(response.data.message == 1){
              this.snackbar = true;
              this.color = "success";
              this.timeout = 7000;
              this.popup_text = "Another OTP sent to " + this.signup_items.mobile_number;

              this.login_div=true;
              this.signup_div=false;

              this.resigtration_otp = true;
              this.dispaly_time_otp = true;
              this.display_resend_otp_text =false;
              this.showRemaining(2); 
            }
            else if(response.data.message == 0){
               this.snackbar = true;
               this.color = "red";
               this.popup_text = "OTP Does not match.";
            }

            else if(response.data.sp_token == '1064'){
              this.isMainDiv = false;
              this.isUnauthorized = true;
              this.CancelURL();
            }
          }).catch(error =>{
             console.log(error);
          });
    },

    //later
    cancel_btn(){
        // var token = this.$route.query.token;
        // var orderid = this.$route.query.order_id;
        // const options = {
        //   method: 'POST',
        //   url: this.base_url+'cancle',
        //   headers: {
        //     Authorization: 'Bearer '+token
        //   },
        //   data:{
        //     orderid
        //   },
        // };
        // axios.request(options).then(response =>{
          
        //   if(response.data.message == 1){
        //       window.location.href = response.data.url;
        //   }

        //   else{
        //     this.snackbar = true;
        //     this.color = "red";
        //     this.popup_text = "Something went wrong...";
        //    }
        // }).catch(error =>{
        //   console.log(error);
        // });

        this.saved_card_tab1 = false;
        this.card_tab = true;

     },

    
    //token applied
    show_singup_form(){
     
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.message== 'ok'){  
                   this.login_div=false;
                   this.signup_div=true;
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    //no need --never used
    show_login_form(){
      this.login_div=true;
      this.signup_div=false;
    },

   //later
    othersCard(){
      this.other_cards_display=true;
      this.hideCard_div=false;
      this.hidecardmenu=false;
      this.hideshowpaynow=false;
    },

    //token applied
    forgot_password(){
      
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.message== 'ok'){  
                  this.forgot_password_modal = true;
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },


    //token applied
    send_otp(){
      this.sp_orderid = this.$route.query.order_id;
      this.sp_token = this.$route.query.token;

      if(typeof(this.sp_token)==='undefined'){
        this.isMainDiv = false;
        this.isUnauthorized = true;
      }

     else{
        const options = {
            method: 'POST',
            url: this.base_url+'chk_token',
            headers: {
              Authorization: 'Bearer '+this.sp_token
            }
          };
            axios.request(options).then(response =>{
                if(response.data.message== 'ok'){  
                  this.send_otp_pre();
                }
               
                else if(response.data.sp_code== '1064'){
                  this.isMainDiv = false;
                  this.isUnauthorized = true;
                  setTimeout(this.CancelURL, 500);
                }
               
            }).catch(error =>{
                if (error.response.status == 401) {
                   this.isMainDiv = false;
                   this.isUnauthorized = true;
                   setTimeout(this.CancelURL, 500);
                  }
          });
      }
    },

    send_otp_pre(){
      let isValid = this.$refs.form2.validate();
      if(isValid){
        var mobile_no = this.forgot_password_mobile;
        var token_check = this.$route.query.token;
        const options = {
          method: 'POST',
          url: this.base_url+'card-holder-forgot-pass',
          headers: {
            Authorization: 'Bearer '+token_check
          },
          data:{
            mobile_no
          }
        };

        axios.request(options).then(response =>{
        if(response.data.message == 1){
          this.forgot_password_modal=false;
          this.forgot_password_otp_modal=true;
          var inputed_mobile = this.forgot_password_mobile;
          var f_part = inputed_mobile.substring(0,3);
          var first_part = inputed_mobile.substring(3, 9);
          var hidden_part = first_part.replace(/[0-9]/g, "*");
          var normal_part = inputed_mobile.substring(9, 11);
          this.otp_send_text_msg = f_part+ hidden_part + normal_part ;
        }

        else if(response.data.message == 3){
            this.snackbar = true;
            this.color = "red";
            this.popup_text = "Couldn't find this mobile number";
        }
        
      }).catch(error =>{
          console.log(error);
      });

      }
    },
    

    //token applied self
    send_active_otp(){
      let isValid = this.$refs.form22.validate();
      if(isValid){
        var mobile_no = this.active_account_mobile;
        var token_check = this.$route.query.token;
        const options = {
          method: 'POST',
          url: this.base_url+'card-holder-forgot-pass',
          headers: {
            Authorization: 'Bearer '+token_check
          },
          data:{
            mobile_no
          },
        };

        axios.request(options).then(response =>{
        if(response.data.message == 1){
          this.active_account_modal=false;
          this.active_acc_otp=true;

          var inputed_mobile = this.active_account_mobile;
          var f_part = inputed_mobile.substring(0,3);
          var first_part = inputed_mobile.substring(3, 9);
          var hidden_part = first_part.replace(/[0-9]/g, "*");
          var normal_part = inputed_mobile.substring(9, 11);
          this.active_account_otp_input_text = f_part+ hidden_part + normal_part ;
          
        }

        else if(response.data.message == 3){
            this.snackbar = true;
            this.color = "red";
            this.popup_text = "Couldn't find this mobile number";
        }
        else if(response.data.sp_token == '1064'){
          this.isMainDiv = false;
          this.isUnauthorized = true;
          this.CancelURL();
        }
        
      }).catch(error =>{
          console.log(error);
      });

      }
    },

  //token applied self
    active_otp_confirm(){
    let check_otp_varification= this.$refs.active_otpp.validate();
    if(check_otp_varification){
    var token = this.$route.query.token;
    var mobile_no = this.active_account_mobile;
    var verify_code = this.active_account_otp_input;

      const options = {
      method: 'POST',
      url: this.base_url+'verify-account',
      headers: {
        Authorization: 'Bearer '+token
      },
      data: {
        mobile_no,
        verify_code,
      },
      
      };

        axios.request(options).then(response =>{
        if(response.data.message == 1){
            this.snackbar = true;
            this.color = "#0F9750";
            this.timeout=8000;
            this.popup_text = "Account Activation successful.";
            this.login_div=true;
            this.signup_div=false;
            this.active_acc_otp = false;
            this.loginItem.login_mobile_number="";
            this.loginItem.login_password="";
        }
        else if(response.data.message == 0){
            this.snackbar = true;
            this.color = "red";
            this.popup_text = "OTP Does not match.";
        }
        else if(response.data.sp_token == '1064'){
          this.isMainDiv = false;
          this.isUnauthorized = true;
          this.CancelURL();
        }
        
      }).catch(error =>{
          console.log(error);
      });
    }
    },

       //token applied
       send_otp_back(){
          this.sp_orderid = this.$route.query.order_id;
          this.sp_token = this.$route.query.token;

          if(typeof(this.sp_token)==='undefined'){
            this.isMainDiv = false;
            this.isUnauthorized = true;
          }

        else{
            const options = {
                method: 'POST',
                url: this.base_url+'chk_token',
                headers: {
                  Authorization: 'Bearer '+this.sp_token
                }
              };
                axios.request(options).then(response =>{
                    if(response.data.message== 'ok'){  
                      this.forgot_password_modal = false;
                    }
                  
                    else if(response.data.sp_code== '1064'){
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      setTimeout(this.CancelURL, 500);
                    }
                  
                }).catch(error =>{
                    if (error.response.status == 401) {
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      setTimeout(this.CancelURL, 500);
                      }
              });
          }
       },

       //Applied via self called
       submit_otp(){
          let isValid = this.$refs.form_otp.validate();
          if(isValid){
            var token = this.$route.query.token;
            var verify_code = this.forgot_password_otp;
            var mobile_no = this.forgot_password_mobile;

             const options = {
              method: 'POST',
              url: this.base_url+'verify-otp',
              headers: {
                Authorization: 'Bearer '+token
              },
              data: {
                  verify_code,
                  mobile_no
              },
              
            };

            axios.request(options).then(response =>{
              if(response.data.message == 1){
                this.forgot_password_otp_modal=false;
                this.forgot_password_modal=false;
                this.new_password_modal = true;
              }
              else if(response.data.message == 0){
                this.snackbar = true;
                this.color = "red";
                this.timeout=3000;
                this.popup_text = "OTP does not matched";
              }
              else if(response.data.sp_code =='1064'){
                this.isMainDiv = false;
                this.isUnauthorized = true;
                this.CancelURL();
              }
            
            }).catch(error =>{
              console.log(error);
            });
             
          }
       },

       //token applied
       submit_otp_back(){
        
         this.sp_orderid = this.$route.query.order_id;
         this.sp_token = this.$route.query.token;

          if(typeof(this.sp_token)==='undefined'){
            this.isMainDiv = false;
            this.isUnauthorized = true;
          }

        else{
            const options = {
                method: 'POST',
                url: this.base_url+'chk_token',
                headers: {
                  Authorization: 'Bearer '+this.sp_token
                }
              };
                axios.request(options).then(response =>{
                    if(response.data.message== 'ok'){  
                       this.forgot_password_otp_modal = false;
                       this.forgot_password_modal = false;
                    }
                  
                    else if(response.data.sp_code== '1064'){
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      setTimeout(this.CancelURL, 500);
                    }
                  
                }).catch(error =>{
                    if (error.response.status == 401) {
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      setTimeout(this.CancelURL, 500);
                      }
              });
          }
       },


      //token applied by self
       resetPassword(){
         let checkVal = this.$refs.form_password_reset.validate();
         if(checkVal){

           var token = this.$route.query.token;
           var mobile_no = this.forgot_password_mobile;
           var c_password = this.confirmed_new_password;
           
           const options = {
              method: 'POST',
              url: this.base_url+'card-holder-pass-change',
              headers: {
                Authorization: 'Bearer '+token
              },
              data: {
                mobile_no,
                c_password,
              },
              
            };

            axios.request(options).then(response =>{
            if(response.data.message == 1){
                this.forgot_password_otp_modal=false;
                this.forgot_password_modal=false;
                this.new_password_modal = false;
  
                this.snackbar = true;
                this.color = "primary";
                this.timeout = 6000;
                this.popup_text = "Your password has been changed successfully.";
            }
            else if(response.data.message == 0){
               this.snackbar = true;
               this.color = "red";
               this.popup_text = "Something went wrong...";
            }
            else if(response.data.sp_code == '1064'){
              this.isMainDiv = false;
              this.isUnauthorized = true;
              this.CancelURL();
            }
           
          }).catch(error =>{
             console.log(error);
             this.$refs.form2.reset();
          });

         }
       },
      
       //token applied
       resetPassword_back(){
        
         this.sp_orderid = this.$route.query.order_id;
         this.sp_token = this.$route.query.token;

          if(typeof(this.sp_token)==='undefined'){
            this.isMainDiv = false;
            this.isUnauthorized = true;
          }

        else{
            const options = {
                method: 'POST',
                url: this.base_url+'chk_token',
                headers: {
                  Authorization: 'Bearer '+this.sp_token
                }
              };
                axios.request(options).then(response =>{
                    if(response.data.message== 'ok'){  
                       this.new_password_modal = false;
                       this.forgot_password_otp_modal = false;
                    }
                  
                    else if(response.data.sp_code== '1064'){
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      setTimeout(this.CancelURL, 500);
                    }
                  
                }).catch(error =>{
                    if (error.response.status == 401) {
                      this.isMainDiv = false;
                      this.isUnauthorized = true;
                      setTimeout(this.CancelURL, 500);
                      }
              });
          }
       },


       //token applied
       logout_user(){
          this.sp_orderid = this.$route.query.order_id;
          this.sp_token = this.$route.query.token;

          if(typeof(this.sp_token)==='undefined'){
            this.isMainDiv = false;
            this.isUnauthorized = true;
          }

          else{
              const options = {
                  method: 'POST',
                  url: this.base_url+'chk_token',
                  headers: {
                    Authorization: 'Bearer '+this.sp_token
                  }
                };
                  axios.request(options).then(response =>{
                      if(response.data.message== 'ok'){  
                        this.logout_user_pre();
                      }
                    
                      else if(response.data.sp_code== '1064'){
                        this.isMainDiv = false;
                        this.isUnauthorized = true;
                        setTimeout(this.CancelURL, 500);
                      }
                    
                  }).catch(error =>{
                      if (error.response.status == 401) {
                        this.isMainDiv = false;
                        this.isUnauthorized = true;
                        setTimeout(this.CancelURL, 500);
                        }
                });
            }
       },

       logout_user_pre(){
        if(localStorage.getItem("mobile_no") === null) {
            console.log("[ShurjoPay] You remove the keys...");
        }
        else{
            var mobile_no = localStorage.getItem('mobile_no');
            var sp_token_check = this.$route.query.token;
            if(mobile_no.length == 11){
                const options = {
                method: 'POST',
                url: this.base_url+'card-holder-logout',
                headers: {
                  Authorization: 'Bearer '+sp_token_check
                },
                data:{
                  mobile_no
                },
              };
              axios.request(options).then(response =>{
                if(response.data.message == 0){
                    localStorage.setItem("isLoggedin", "0");
                    localStorage.setItem("mobile_no", mobile_no);
                    this.myaccount = false;
                    this.no_login = true;
                    this.login_div =true;
                   // window.location.reload();
                }
                

              }).catch(error =>{
                  console.log(error);
              });

            } 

        }
        
       },

      
      //no need
      SavedcardsAction(){
          this.saved_card_section =true;
          this.hideCard_div=false;
          this.hidecardmenu=false;
          this.hideshowpaynow=false;
          this.other_cards_display =false;
      },

      //no need
      show_card_form(){
        this.saved_card_tab1 = true;
        this.card_tab = false;
      },

      //no need
      display_toc(){
        this.show_terms_conditions = true;
        this.toc_dialog = true;
      },

      //no need
      select_card_icon(val){
          let first_digit = val.charAt(0);
          let second_digit = val.charAt(1);
          let first_two_digit = val.substring(0, 2)
          
          if(first_two_digit==34 || first_two_digit==37 ){
            this.amexActive = true;
            this.amexDeactive = false;
            this.user_entered_card_name ="american-express";
            this.isSelectedCard = true;
          }

          if(first_digit== 3 && (second_digit ==0 || second_digit==6 || second_digit==8)){
              this.dinersActive = true;
              this.dinersDeactive = false;
              this.user_entered_card_name = "dinners-club";
              this.isSelectedCard = true;
          }

          if(first_digit==4){
            this.visaDeactive=false;
            this.visaActive=true;
            this.user_entered_card_name="brac-visa";
            this.isSelectedCard = true;
          }

          if((first_digit==5 && (second_digit>=1 && second_digit<=5)) || 
          (first_digit==2 && (second_digit>=2 && second_digit<=7))){
            this.masterCardDeactive=false;
            this.masterCardActive=true;
            this.user_entered_card_name="brac-master";
            this.isSelectedCard = true;
          }

          if(first_digit==6){
            this.unionpayDeactive=false;
            this.unionpayActive=true;
            this.user_entered_card_name="union-pay";
            this.isSelectedCard = true;
          }

          if(first_digit==0 ||first_digit==1 ||first_digit==7 ||first_digit==8 ||first_digit==9 ){
            this.snackbar = true;
            this.color = "red";
            this.timeout=2000;
            this.popup_text = "Enter a valid card number.";
            this.isSelectedCard = false;
            this.masterCardDeactive=true;
            this.masterCardActive=false;
            this.visaDeactive=true;
            this.visaActive=false;
            this.amexDeactive=true;
            this.amexActive=false;
            this.unionpayDeactive=true;
            this.unionpayActive=false;
            this.dinersDeactive=true;
            this.dinersActive=false;
          }
      },

      //no need
      transfer_number(selected_number){
        this.others.mobile_number = selected_number;
        document.getElementById("bks").focus();
      },
      
      //later
      delete_bks_num(delete_num,index){
        //console.log(delete_num);
        //console.log(index);
        //this.saved_bkash.splice(index, 1);
        var sp_token = this.$route.query.token;
        var mobile_no = delete_num;
        const options = {
            method: 'POST',
            url: this.base_url+'bkash-cancel-agreement',
            headers: {
              Authorization: 'Bearer '+ sp_token
            },
            data: {
              mobile_no
            },
            };
            axios.request(options).then(response =>{
              if(response.data.message==1){
                this.saved_bkash.splice(index, 1);
                // this.popup_dialog =false;
                // this.loading_tab=false;
                // this.loading_dialog=false;
              }
            }).catch(error =>{
              console.log(error);
            });

      },

    },

}
</script>

<style scoped>

  /* Gateway hover and normal colors --start */
  .normal_img{
    cursor:pointer;
    border-bottom:4px solid #fff;
    border-radius:8px;
  }
  .hover_img{
    cursor:pointer;
    border-bottom:4px solid #0F9750;
    border-radius:8px;
  }
  /* Gateway hover and normal colors --end */

  .fastpay_div{
    margin-top:-10px !important;
    margin-left:2px !important;
    margin-right:2px !important;
    margin-bottom:9px !important;
  }
   .fastpaytext{
      margin-bottom: 0px !important;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      font-weight: bold;
      font-size: 16x;
    }

  .page_bg{
    background-color: #f2f2f2 !important;
  }

  .text_style{
    font-size:13px;
    font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
    margin-right:-10px;
  }
  .style_signup_btn{
     margin-top: 3px;
     margin-left:-117px;
     font-size: 14px;
     text-transform: none;
     font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
  }

  .forgot_btn{
    font-size: 13px;
    text-transform: none;
    font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
    margin-top: -10px;
    margin-left: 90px;
  }
  .style_login_btn{
     margin-top: 0px;
     margin-left: 0px;
     font-size: 14px;
     text-transform: none;
     font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
  }
  .other_btn{
    margin-left:-5px !important;
    text-transform:none;
    font-size: 13px;
    font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
    font-weight: bold;
  }

  .saved_card_btn{
    text-transform:none;
    margin-left:-14px;
    font-size: 13px;
    font-family: Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
    font-weight: bold;
  }
 
  .emiDisplaytext{
    font-size:13px !important;
  }

    .paynow_div{
      margin-left:0px;
      margin-right:0px;
      min-height:70px;
      margin-top:14px;
    }

    .paynow_div_tdr{
      margin-left:25px;
      margin-right:25px;
      min-height:70px;
      margin-top:0px;
    }

    .main_div{
      margin-top:-30px;
     
    }
   
   .card_style{
        background-color:#ffffff;
        margin-top:100px !important;
    }

    .avatar_div{
      background-color: #fff;
      border-radius:15px 15px 0px 0px !important;
      height:56px;
    }

    .avatar_img{
        display: block;
        border-radius: 100%;
        margin: 4px;
        border: 4px solid #0F9750;
        margin-right:-7px !important;
      }

  
    .name_payment_amount{
        border-radius: 20px;
        background: #0F9750;
        padding: 10px;
    }

    .payment_to{
        font-size: 18px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:5px;
    }
    

    .client_name_andLogout{
        font-size: 16px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#000;
        margin-top:0px;
        text-align: right;
    }

    .logout_btn{
        font-size: 16px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        text-transform: none;
        color:#0F9750 !important;
        padding:0px !important;
        height: 30px !important;
        margin-top:-2px;
    }
    .client_name{
        font-size: 14px;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-18px;
    }

     .payment_amount{
        font-size: 18px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-16px;
    }

    .order_id{
        font-size: 13px;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-21px;
    }

    .vertical_line{
        border-left: 2px dotted #0F9750;
        height:auto;
    }

   .pay_amount_main_div{
      background-color:#f2f2f2;
      /* height: 60px; */
      margin:5px;
      border: 2px solid #f2f2f2;
      padding:5px;
   
   }
   .name_logout{
      height: 30px;
      margin: 5px;
      padding:6px;
   }

   .border_left{
     border-left: 2px dotted #000000;
     height: 40px;
     margin-top: 22px;
   }

   .border_left2{
     border-left: 2px dotted #000000;
     height: 80px;
     margin-top: 22px;
   }

   .paynow_btn{
     font-size: 16px;
     font-weight: bolder;
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
     min-height:40px !important;
     text-transform: uppercase;
   }

   .paynow_btn_tdr{
     font-size: 16px;
     font-weight: bolder;
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
     min-height:40px !important;
     text-transform: uppercase;
   }

   .tabs_text_style{
     text-transform: none;
     color:#fff !important;
     font-size: 14px;
     background-color:#0F9750;
     border-radius:5px;
     margin-right:3px; 
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }
   .tabs_text_style_mobile_banking{
     text-transform: none;
     color:#fff !important;
     font-size: 14px;
     background-color:#0F9750;
     border-radius:5px;
     margin-right:3px; 
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }

   .lasttabs_text_style{
     text-transform: none;
     color:#fff !important;
     font-size: 14px;
     background-color:#0a867b;
     border-radius:5px;
     margin-right:0px;
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

   }

   .tos{
    font-size: 14px;
    margin-top: -12px;
    padding-left: 7px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-top: 3px solid #f3eeee;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }


   .rowStyle{
     margin-top:-12px !important;
     margin-left:0px !important;
     margin-right:0px !important;
   }
  
   .containerStyle{
     margin-top:5px !important;
     min-height:250px !important;
   }
   .internetBankingSec{
     margin-top:5px !important;
     min-height:250px !important;
   }
   .mobileBankingSec{
     margin-top:5px !important;
     min-height:250px !important;
   }

   /* override default properties of v-tabs */
   .v-tabs-slider{
     color:#df2c2c !important;
     border-radius:10px;
   }
   
  /* If the browser window is 403px or smaller*/
   /* @media only screen and (max-width: 403px) {

    .tabs_text_style {
      font-size: 10px !important;
      background-color:#0F9750;
      border-radius:3px !important;
      margin-right:2px !important;

    }

    .lasttabs_text_style{
     font-size: 10px !important;
     background-color:#0F9750;
     border-radius:3px !important;
     margin-right:0px !important;
   }
  .time_left{
      font-size: 14px !important;
      font-weight: normal;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  }
  .avatar_img{
        display: block;
        border-radius: 100%;
        margin: 4px;
        border: 4px solid #0F9750;
      }
  
  } */

  @media only screen and (min-width: 376px) and (max-width: 404px) {
  
    .tabs_text_style {
      font-size: 10px !important;
      background-color:#0F9750;
      border-radius:3px !important;
      margin-right:2px !important;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .lasttabs_text_style{
     font-size: 10px !important;
     border-radius:3px !important;
     margin-right:0px !important;
     font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
   }
  .time_left{
      font-size: 14px !important;
      font-weight: normal;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  }
  .avatar_img{
        display: block;
        border-radius: 100%;
        margin: 4px;
        border: 4px solid #0F9750;
      }
  }

  @media only screen and (min-width: 320px) and (max-width: 376px) {
    
    .tabs_text_style {
      font-size: 10px !important;
        background-color:#0F9750;
        border-radius:3px !important;
        margin-right:1px !important;
        margin-left:-5px !important;
      
      }
      .lasttabs_text_style{
      font-size: 10px !important;
      border-radius:3px !important;
      margin-right:0px !important;
      margin-left:-5px !important;
    }
    .time_left{
      font-size: 14px !important;
      font-weight: normal;
      font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .avatar_img{
      display: block;
      border-radius: 100%;
      margin: 4px;
      border: 4px solid #0F9750;
    }
    .order_id{
      font-size: 10px !important; 
    }
   
  }

  @media only screen and (max-width: 422px) {
  .payment_to{
      font-size: 16px;
  }

  .client_name{
      font-size: 13px;
    }

    .payment_amount{
      font-size: 15px;
    } 

  .order_id{
      font-size: 11px; 
  }
  .avatar_img{
      display: block;
      border-radius: 100%;
      margin: 4px;
      border: 4px solid #0F9750;
    }


    .tdr_amount_label{
        font-size: 11px !important;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-13px;
    }
    .amount_label{
        font-size: 11px !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        margin-top:-13px;
        margin-left:5px !important;
    }

    .additional_fees{
       font-size: 11px !important;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-21px !important;
        
    }
    .tdrInfoBtn{
      font-size: 12px !important;
      margin-left: 5px !important;
      color:rgb(224, 205, 101) !important;
    }

    .border_left2{
     border-left: 2px dotted #000000;
     height: 60px;
     margin-top: 22px;
   }

  }


  /* TDR Design 2 start*/
     .tdr2{
        font-size: 11px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-13px;
        margin-left:0px !important;
        /* background-color: #fff !important; */
      }


      .tdr3{
        font-size: 11px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        margin-top:-13px;
        /* margin-left:5px !important; */
      }


       .tdr4{
        font-size: 16px;
        font-weight: bold;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-16px;
    }

  /* TRD Design 2 end */


  /* TDR */
   .tdr_amount_label{
        font-size: 14px;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-13px;
        /* background-color: #fff !important; */
    }
    .amount_label{
        font-size: 13px;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        margin-top:-13px;
        margin-left:5px !important;
    }

    .additional_fees{
       font-size: 14px;
        font-weight: normal;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        color:#0F9750;
        margin-top:-21px !important;
    }
    .tdrInfoBtn{
      font-size: 13px !important;
      margin-left: 10px;
      color:rgb(224, 205, 101) !important;
    }


</style>


