<template>
    <div style="margin-top:-70px;">
        <v-card class="mx-auto my-12" max-width="450" elevation="0" v-if="default_sec">
            <v-container  grid-list-xl justify-space-around wrap fluid>
                <v-layout wrap class="row_style">
                    <v-flex xs6 md6 sm6>
                        <v-list subheader two-line>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">
                                    <v-icon size="80" left color="#0F9750">
                                        mdi-account
                                </v-icon>
                                </v-list-item-title>
                                <v-list-item-subtitle class="account_text">Manage Account</v-list-item-subtitle>
                            </v-list-item-content> 
                        </v-list-item>
                        </v-list>  
                    </v-flex>

                    <!-- @click="transactionHistory()" -->
                    <v-flex xs6 md6 sm6>
                        <v-list subheader two-line>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">
                                    <v-icon size="80" left color="#0F9750">
                                        mdi-file-multiple
                                </v-icon>
                                </v-list-item-title>
                                <v-list-item-subtitle class="account_text" >Transaction History</v-list-item-subtitle>
                            </v-list-item-content> 
                        </v-list-item>
                        </v-list>  
                    </v-flex>
                </v-layout>


                <v-layout wrap style="margin-bottom:-70px">
                    <v-flex xs6 md6 sm6>
                        <v-list subheader two-line>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">
                                    <v-icon size="80" left color="#0F9750">
                                        mdi-credit-card-multiple
                                </v-icon>
                                </v-list-item-title>
                                <v-list-item-subtitle class="account_text" >Saved Cards</v-list-item-subtitle>
                            </v-list-item-content> 
                        </v-list-item>
                        </v-list>  
                    </v-flex>
                </v-layout>


            </v-container>
        </v-card>

        <div v-if="fp" class="fastPay">
            <FastPay></FastPay>
        </div>

        <!-- Transaction History start -->
        <v-dialog v-model="transaction_history" max-width="400px"  persistent>
            <v-card class="mx-auto" max-width="400" style="min-height:450px">

                <v-toolbar color="#0F9750" dark>
                <v-toolbar-title class="modal_title">Transaction History</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="ClosePopUp()" title="Close"> <v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                
                <v-list two-line>
                <v-list-item-group v-model="selected" active-class="blue--text">
                   
                    <template v-for="(item, index) in items">
                    <v-list-item :key="item.index" title="Click here to view detail">
                        <template v-slot:default="{ active }">
                            <v-list-item-content>
                                <v-list-item-title class="DateStyle">{{item.date}}</v-list-item-title>
                                <v-list-item-subtitle class="text--primary">{{item.headline}}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{item.vendor}}</v-list-item-subtitle>


                                <!-- Specific transaction details -->

                               
                                <v-list-item-title v-if="active" class="paid_with">Paid with</v-list-item-title>
                                <v-list-item-subtitle v-if="active" class="details">VISA ****3148</v-list-item-subtitle>
                                <v-list-item-title v-if="active" class="details">Seller Info</v-list-item-title>
                                <v-list-item-subtitle v-if="active" class="details">Chaldal</v-list-item-subtitle>
                                <v-list-item-title v-if="active" class="details">Invoice ID</v-list-item-title>
                                <v-list-item-subtitle v-if="active" class="details">861742F79104FE56</v-list-item-subtitle>

                                <v-list-item-title v-if="active" class="details">Purchase details</v-list-item-title>
                                <v-list-item-subtitle v-if="active" class="details">Order # 6457079</v-list-item-subtitle>
                                <v-list-item-title v-if="active" class="details">Date</v-list-item-title>
                                <v-list-item-subtitle v-if="active" class="details">22 Oct, 2021 12:28pm</v-list-item-subtitle>

                                <!-- <v-simple-table v-if="active" dense style="border:none">
                                <tbody>
                                    <tr style="border:none">
                                        <td class="text-left details">Paid with</td>
                                        <td class="text-left details">:</td>
                                        <td class="text-left details">VISA ****3148</td>
                                    </tr>

                                    <tr >
                                        <td class="text-left details">Seller Info</td>
                                        <td class="text-left details">:</td>
                                        <td class="text-left details">Chaldal</td>
                                    </tr>

                                    <tr style="border:none">
                                        <td class="text-left details">Invoice ID</td>
                                        <td class="text-left details">:</td>
                                        <td class="text-left details">861742F79104FE56</td>
                                    </tr>

                                    <tr>
                                        <td class="text-left details">Order ID</td>
                                        <td class="text-left details">:</td>
                                        <td class="text-left details">6457079</td>
                                    </tr>

                                    <tr>
                                        <td class="text-left details">Date</td>
                                        <td class="text-left details">:</td>
                                        <td class="text-left details">22 Oct, 2021 12:28pm</td>
                                    </tr>
                                    </tbody>
                                </v-simple-table> -->

                            </v-list-item-content>

                        <v-list-item-action>
                            <v-list-item-action-text class="amount_style">{{item.amount}}</v-list-item-action-text>
                            <v-icon v-if="!active" color="grey lighten-1" size="35px">mdi-menu-down</v-icon>
                            <v-icon v-else color="primary" size="35px">mdi-menu-up</v-icon>
                        </v-list-item-action>
                        </template>
                    </v-list-item>

                    <v-divider
                        v-if="index < items.length - 1"
                        :key="index"
                    ></v-divider>
                    
                    </template>
                   
                </v-list-item-group>
                </v-list>

               <v-card-actions 
                style="background-color:#f2f2f2;cursor:pointer"
                class="justify-center"
                @click="GoMorePage()"
                v-if="isTransactionMoreThanFive"
                >
                    <v-btn text large class="more_btn_class">More ...</v-btn>
               </v-card-actions>
                

            </v-card>
            
        </v-dialog>
         <!-- Transaction History end -->

    </div>
     
</template>

<script>
import FastPay from '../components/FastPay';
export default {

    components:{
        FastPay,
    },

    data: () => ({
        default_sec:true,
        fp:false,
        transaction_history:false,
        isTransactionMoreThanFive:false,
        
        selected: [0],
        items: [
        {
          amount: 'BDT 1025',
          headline: 'Payment',
          vendor: 'Pranisheba Shop',
          date: '25 March 2021',
        },
        {
          amount: 'BDT 85523',
          headline: 'Payment',
          vendor: 'Chaldal',
          date: '10 January 2021',
        },
        {
          amount: 'BDT 180',
          headline: 'Payment',
          vendor: 'Pranisheba Shop',
          date: '08 February 2020',
        },
        {
          amount: 'BDT 9999',
          headline: 'Payment',
          vendor: 'Daraz',
          date: '10 January 2020',
        },
        {
          amount: 'BDT 780',
          headline: 'Payment',
          vendor: 'Pranisheba Shop',
          date: '19 August 2021',
        },
      ],
      }),

    mounted() {
        
        // transaction history dynamic
        var trx_items_no = this.items.length;
        if(trx_items_no>=5){
            this.isTransactionMoreThanFive = true;
        }
        else{
            this.isTransactionMoreThanFive = false;
        }

    },

    methods: {

        saved_cards(){
            this.default_sec = false;
            this.fp = true;
            this.$refs.modal.show_saved_cards();
        },

        transactionHistory(){
          this.transaction_history = true;
        },

        ClosePopUp(){
            this.transaction_history = false;
        },

        GoMorePage(){
            console.log("Go more page...");
        }
    }


  
}
</script>

<style scoped>

    .fastPay{
        margin-top: 70px !important;
        min-height:250px;
    }
    .row_style{
        margin-bottom:-23px !important;
    }
    .account_text{
        font-size: 16px;
        font-weight: bolder;
        text-transform:none;
        color:black;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        text-align: center !important;
    }
    .DateStyle{
        font-size: 16px;
        font-weight: bolder;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .details{
        margin-left:10px !important;
        font-size:13px !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        border: none !important;
    }
    .paid_with{
        margin-top:10px;
        margin-left:10px;
        font-size:13px !important;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    .more_btn_class{
        text-transform: none;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        font-size: 16px;
        font-weight: bolder;
        color:black;
       
    }

    .amount_style{
        color: black !important;
        font-size: 14px;
        font-weight: bolder;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;

    }
    .modal_title{
        font-size: 20px;
        font-weight: bolder;
        font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    }
    

    /* Responsive */
    @media only screen and (min-width: 370px) and (max-width: 406px) {
        .account_text{
            font-size: 14px;
            font-weight: normal !important;
            text-transform:none;
            color:rgb(0, 0, 0) !important;
            font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
            text-align: center !important;
      }
    }

    @media only screen and (min-width: 310px) and (max-width: 370px) {
        .account_text{
            font-size: 10px;
            font-weight: normal !important;
            text-transform:none;
            color:rgb(0, 0, 0) !important;
            font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
            text-align: center !important;
      }
    }
    
    
</style>