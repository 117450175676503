module.exports = {
    // base_url:"https://engine.shurjopayment.com/api/",
    // image_base_url:"https://engine.shurjopayment.com/",
    // merchant_logo_image_base_url:"https://admin.shurjopayment.com/",
    // cancel_base_url:"https://engine.shurjopayment.com/",

    /* Staging server */
    base_url: "https://staging.engine.shurjopayment.com/api/",
    image_base_url: "https://staging.engine.shurjopayment.com/",
    merchant_logo_image_base_url: "https://staging.admin.shurjopayment.com/",
    cancel_base_url: "https://staging.engine.shurjopayment.com/",

    /*Base URLs for Local server*/
    // base_url: "http://localhost/engine-live/api/",
    // image_base_url: "http://localhost/engine-live/",
    // merchant_logo_image_base_url: "http://localhost/engine-live/",
    // cancel_base_url: "http://localhost/engine-live/",
}
